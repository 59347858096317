import React, { useState, useId, useMemo, MouseEvent } from 'react';
import Popover, { PopoverOrigin } from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import styles from './popover.module.css';

export default function BasicPopover({ component, content }: any) {
  const [anchorEl, setAnchorEl] = useState<any | null>(null);

  const id = useId();

  const handleClick = (event: MouseEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const ancorStyle = useMemo(() => {
    return {
      vertical: 'bottom',
      horizontal: 'left',
    } as PopoverOrigin;
  }, []);

  return (
    <div>
      <div aria-describedby={id} onClick={handleClick}>
        {component}
      </div>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={ancorStyle}
        className={styles.popover}
        PaperProps={{
          style: {
            backgroundColor: '#202123', 
            boxShadow: 'unset',
            border: '1px solid #40414F',
            borderRadius: 5,
            width: 324,
          },
        }}
      >
        <Typography>{content}</Typography>
      </Popover>
    </div>
  );
}
