import { createSelector } from 'reselect';
import { ISessionState, RootState } from '../../../models/store/store-models';

export const selectSessionSlice = (state: RootState): ISessionState =>
  state.session;

export const selectSessionId = createSelector(
  selectSessionSlice,
  (state: ISessionState) => state.sessionId,
);

export const selectSessionStartHistory = createSelector(
  selectSessionSlice,
  (state: ISessionState) => state.startSessionHistory,
);
