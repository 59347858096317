import { useCallback, useMemo, useState } from 'react';
import contextImg from 'images/placeholder_context.png';
import styles from './database.module.css';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

// Flow diagram lib's
import { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/style.css';

import EntityDiagram from '../entity-diagram';
import { IDatabaseInfo, IDatabase } from 'models/store/store-models';

interface IDatabaseProps {
  connection: IDatabaseInfo;
  open: boolean;
  onClose: (...args: any[]) => any
}

const Database = ({ connection, open, onClose }: IDatabaseProps) => {
  const [animate, setAnimate] = useState(false);



  const handleClose = useCallback(() => {
    setAnimate(false);
    onClose(false);
  }, []);

  const BackdropProps = useMemo(
    () => ({
      style: {
        backgroundColor: 'rgba(0, 0, 0, 0)',
      },
    }),
    [],
  );

  const PaperProps = useMemo(
    () => ({
      style: {
        width: '80%',
        height: '80%',
        borderRadius: 10,
        backgroundColor: '#1A1B20',
      },
    }),
    [],
  );

  return (
    <>
      <Dialog
        fullScreen
        BackdropProps={BackdropProps}
        open={open}
        onClose={handleClose}
        className={animate ? styles.animated_dialog : ''}
        PaperProps={PaperProps}
      >
        <DialogContent>
          <ReactFlowProvider>
            {connection.databases.map((database: IDatabase, index: number) => {
              return <EntityDiagram database={database} key={index} />;
            })}
          </ReactFlowProvider>
        </DialogContent>
      </Dialog>
    </>
  );
};
export default Database;
