import React, { FC } from 'react';
import { BsDatabase, BsArrowUpShort } from 'react-icons/bs';
import { TextField, InputAdornment } from '@mui/material';
import ToolTip from 'components/tooltip';
import styles from './chat-bottom.module.css';

interface Props {
  message: string;
  textareaRef: React.RefObject<any>;
  handleChange: (e: React.ChangeEvent<any>) => void;
  handleSendMessage: (message: string) => void;
  setDatabaseModal: (value: boolean) => void;
  trackSendMessageButton: () => void;
}

const ChatBottomSection: FC<Props> = ({
  message,
  textareaRef,
  handleChange,
  handleSendMessage,
  setDatabaseModal,
  trackSendMessageButton,
}) => {
  const buttonStyle =
    message.length > 0
      ? {
          backgroundColor: '#0F52BA',
          color: '#fff',
        }
      : {};
  return (
    <div className={styles.bottom_section}>
      <TextField
        id="outlined-multiline-flexible"
        multiline
        maxRows={4}
        value={message}
        inputRef={textareaRef}
        onChange={handleChange}
        placeholder="Message SQL Workstreams..."
        className={styles.textarea_wrap}
        onKeyPress={(event) => {
          if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSendMessage(message);
          }
        }}
        InputProps={{
          endAdornment: (
            <>
              <ToolTip title={'Database'} enterDelay={100} placement="top">
                <InputAdornment position="end">
                  <BsDatabase
                    className={styles.database_icon}
                    onClick={(e) => {
                      setDatabaseModal(true);
                    }}
                  />
                </InputAdornment>
              </ToolTip>
              <ToolTip title={'Send message'} enterDelay={100} placement="top">
                <InputAdornment position="end">
                  <BsArrowUpShort
                    className={styles.send_icon}
                    style={buttonStyle}
                    onClick={(e) => {
                      trackSendMessageButton();
                      handleSendMessage(message);
                    }}
                  />
                </InputAdornment>
              </ToolTip>
            </>
          ),
        }}
      />

      <p className={styles.information}>
        Workstreams can make mistakes. Consider checking important information.
      </p>
    </div>
  );
};

export default ChatBottomSection;
