import { IActionPayload, IMainState } from 'models/store/store-models';
import { createSlice } from '@reduxjs/toolkit';
import { PreferencesProfile } from 'models/profile';

export const initialState: IMainState = {
  preferencesProfile: undefined,
};

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    setPreferencesProfile(
      state: IMainState,
      action: IActionPayload<PreferencesProfile | undefined>,
    ) {
      state.preferencesProfile = action.payload;
    },
  },
});

export const { setPreferencesProfile } = mainSlice.actions;
export default mainSlice.reducer;
