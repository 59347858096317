import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import styles from './radio-field.module.css';

interface InputProps {
  value: any;
  options: any;
  onChange: (e: any) => void;
}

const RadioField = ({ value, onChange, options }: InputProps) => {
  return (
    <RadioGroup
      row
      aria-labelledby="demo-row-radio-buttons-group-label"
      name="row-radio-buttons-group"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    >
      {options.map((obj: any) => (
        <FormControlLabel
          className={styles.option}
          value={obj.value}
          control={<Radio />}
          label={obj.label}
        />
      ))}
    </RadioGroup>
  );
};

export default RadioField;
