import {
  AiFillDislike,
  AiFillLike,
  AiOutlineDislike,
  AiOutlineLike,
  AiFillStar,
} from 'react-icons/ai';
import { FC, useState, useEffect } from 'react';
import Popup from 'components/popup';
import { sendFeedback } from 'service';
import styles from './feedback-button.module.css';

//App insights tracking
import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js';
import { useDispatch } from 'react-redux';
import { setToastData } from 'store/slices/core/slice';

interface Props {
  messageId?: string;
  isResetOnMessageID?: boolean;
  sessionID?: string;
  isFullMessageCopy?: boolean;
  isShowModalOnLike?: boolean;
}

const FeedbackButton = ({
  isFullMessageCopy = false,
  messageId,
  isResetOnMessageID,
  sessionID,
  isShowModalOnLike,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [thumbsUp, setThumbsUp] = useState<any>();
  const [isSelected, setIsSelected] = useState(false);
  const dispatch = useDispatch();

  // Tracking
  const appInsights = useAppInsightsContext();
  const trackOpenFeedBackModal = useTrackMetric(
    appInsights,
    'FEEDBACK-MODAL-open',
  );
  const trackSentFeedBack = useTrackMetric(
    appInsights,
    'FEEDBACK-MODAL-sent-opinion',
  );

  useEffect(() => {
    if (isResetOnMessageID) {
      setIsOpen(false);
      setIsSelected(false);
      setThumbsUp(null);
      dispatch(setToastData({ open: false }));
    }
  }, [isResetOnMessageID, messageId, dispatch]);

  const handleOpen = (e: any, value: boolean) => {
    e.stopPropagation();
    trackOpenFeedBackModal();
    if (!isSelected) {
      setThumbsUp(value);
      setIsSelected(true);
      if (isShowModalOnLike || !value) {
        setIsOpen(true);
      } else {
        handleSubmit({ thumbsUp: true });
      }
    }
  };

  const handeClose = () => {
    setIsOpen(false);
    // setThumbsUp(null)
    setIsSelected(true);
    handleSubmit({ sessionID, chatID: messageId });
  };

  const handleSubmit = (value: any) => {
    trackSentFeedBack();
    console.log('thumbsUp', thumbsUp);
    sendFeedback(
      { thumbsUp: thumbsUp, ...value, sessionID, chatID: messageId },
      (res: any) => {
        dispatch(
          setToastData({
            open: true,
            message: 'Feedback saved!',
            type: 'success',
          }),
        );
      },
      (err: any) => {
        dispatch(
          setToastData({
            open: true,
            message: 'Feedback not saved!',
            type: 'error',
          }),
        );
      },
    );
    setIsOpen(false);
  };

  return (
    <>
      {isFullMessageCopy ? (
        <div
          onClick={(e) => handleOpen(e, true)}
          className={styles.feedBackButton}
        >
          <div className={styles.star_icon}>
            <AiFillStar />
          </div>
          <p className={styles.feedback_text}>Feedback</p>
        </div>
      ) : (
        <div className={styles.thumbsUp_container}>
          {thumbsUp !== false &&
            (isSelected ? (
              <AiFillLike />
            ) : (
              <AiOutlineLike
                className={styles.thumbButton}
                onClick={(e) => handleOpen(e, true)}
              />
            ))}
          {!thumbsUp &&
            (isSelected ? (
              <AiFillDislike />
            ) : (
              <AiOutlineDislike
                className={styles.thumbButton}
                onClick={(e) => handleOpen(e, false)}
              />
            ))}
        </div>
      )}

      {isOpen && (
        <Popup
          isDefaultIcon={false}
          liked={thumbsUp}
          showExtraField={!thumbsUp}
          handleClose={handeClose}
          handleSubmit={handleSubmit}
          headerText={'Provide additional feedback'}
          placeholder={
            'What was the issue with the response? How could it be improved?'
          }
        />
      )}
    </>
  );
};

export default FeedbackButton;
