import { Checkbox, FormControlLabel } from "@mui/material"
import styles from './checkbox-field.module.css'

interface Props {
    label?: string;
    value?: any;
    onChange?: (value: any) => void;
}

const CheckboxField = ({ value, label, onChange }: Props) => {

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange?.(event.target.checked);
    };

    return (
        <div>
            <FormControlLabel className={styles.input_wrap} control={<Checkbox checked={value} onChange={handleChange} />} label={label} />
        </div>
    )
}

export default CheckboxField