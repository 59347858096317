import React, { useEffect, useCallback } from 'react';
import styles from './onboarding-modal.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { setShowOnBoardModal } from 'store/slices/core/slice';
import useClickOutside from 'utils/hooks/useClickOutside';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { setToastData } from 'store/slices/core/slice';
import { useState, useRef } from 'react';
import QuestionSlider from 'containers/question-slider';


const OnBoardingModal = ({ onClose }: any) => {
  const [isOnBoardShowModal, setIsOnBoardShowModal] = useState(true);


  //redux state
  const { showOnBoardModal } = useSelector((state: any) => state.core);
  const dispatch = useDispatch();

  // onClick outside ref
  const ref = useRef<HTMLDivElement>(null);

  const onClickOutside = useCallback(() => {
    dispatch(setShowOnBoardModal(false));
    setIsOnBoardShowModal(false);
    onClose();
  }, []);

  useClickOutside(ref, onClickOutside);

  // useEffect(() => {
  //   if (showOnBoardModal) {
  //     setIsOnBoardShowModal(true);
  //   }
  // }, [showOnBoardModal]);

  if (!isOnBoardShowModal) {
    return <></>;
  }
  /////

  return (
    <div className={styles.popup}>
      <div className={styles.popup_inner} ref={ref}>
        <div className={styles.content_wrap}>
          <div className={styles.wraper}>
          <QuestionSlider onClose={() => {}} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OnBoardingModal;
