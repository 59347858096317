import React from 'react';
import { BsAt } from 'react-icons/bs';
import styles from './bot-name.module.css';

const BotName: React.FC = () => {
  return (
    <div className={styles.chatset}>
      <div className={styles.icon_container}>
        <div className={`${styles.badge}`}>
          <BsAt />
        </div>
      </div>
      <div className={styles.name_text}>Lumis</div>
    </div>
  );
};

export default BotName;
