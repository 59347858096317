import Dashboard from '../dashboard';
import Enrollment from 'containers/enrollment';
import { useDispatch } from 'react-redux';
import { useAuth, withAuth } from 'oidc-react';
import { useEffect, useState } from 'react';
import { setupConnection } from 'utils/hooks/useSignalrConnection';
import { HomeLoading } from './loading';
import styles from './home-loading.module.css';
import { GetSettingsService } from 'api/setup/setup';
import { setSettings } from 'store/slices/core/slice';

function Home() {
  const showEnrollmentOverlay = false;
  const dispatch = useDispatch();
  const auth = useAuth();
  const [isSignalRConnected, setIsSignalR] = useState<boolean>(false);

  useEffect(() => {
    if (auth?.userData) {
      setupConnection(dispatch).then((res) =>
        setIsSignalR(res?.isConnected || false),
      );
    }
  }, [auth, dispatch]);

  useEffect(() => {
    if (isSignalRConnected) {
      const getSettings = async () => {
        const service = GetSettingsService();
        const res = await service.getConfig();
        if (res && res.isSuccessful) {
          dispatch(setSettings(res.data));
        }
      };
      getSettings();
    }
  }, [dispatch, isSignalRConnected]);

  return auth.userData && isSignalRConnected ? (
    <div className={styles.root}>
      {showEnrollmentOverlay && <Enrollment />}
      <div className={styles.dashboard_content}>
        <Dashboard />
      </div>
    </div>
  ) : (
    <HomeLoading />
  );
}

export default withAuth(Home);
