import React, { useCallback, useMemo } from 'react';
import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js';

import { useDispatch, useSelector } from 'react-redux';
import { selectCoreSlice } from 'store/slices/core/selectors';
import { setShowConnectionModal } from 'store/slices/core/slice';
import styles from './splash-screen.module.css';
import { IoMdArrowRoundUp } from 'react-icons/io';
import logo from 'images/logo.svg';
import useResponsive from 'utils/hooks/useResponsive';
import ToolTip from 'components/tooltip';

interface Props {
  setMessages: (value: any) => void;
  bottomRef?: any;
}

const exampleQuestions = [
  {
    title: 'Write a query',
    subtitle: 'to select all the table names.',
    question: 'Write a query to select all the table names.',
  },
  {
    title: 'Subqueries',
    subtitle: 'to select the relational data',
    question: 'Subqueries to select the relational data.',
  },
  {
    title: 'Optimization',
    subtitle: 'of a slow query',
    question:
      'What are the top 10 slowest queries, and how can they be optimized?',
  },
  {
    title: "What is the database's",
    subtitle: 'storage capacity and current usage?',
    question: "What is the database's storage capacity and current usage?",
  },
];

const SplashScreen: React.FC<Props> = ({ setMessages, bottomRef }) => {
  const dispatch = useDispatch();
  const connectionData = useSelector(selectCoreSlice).connectionData;
  const showConnectionBtn = useSelector(selectCoreSlice).showConnectionBtn;
  const {isMobile}  = useResponsive()
  const bottomHeight = bottomRef?.current?.offsetHeight;
  // Tracking
  const appInsights = useAppInsightsContext();
  const trackOpenCreateConnectionModal = useTrackMetric(
    appInsights,
    'CREATE-CONNECTION-MODAL-open',
  );

  const handleClick = useCallback((value: string) => {
    setMessages(value);
  }, [setMessages]);

  const onCreateConnection = useCallback(() => {
    trackOpenCreateConnectionModal();
    dispatch(setShowConnectionModal(true));
  }, [dispatch, trackOpenCreateConnectionModal]);

  const dataContentStyle = useMemo(() => {
    return {
      bottom: bottomHeight + 10,
    };
  }, [bottomHeight]);

  const questions = useMemo(()=> isMobile ? exampleQuestions.slice(0, 2) : exampleQuestions, [isMobile])

  return (
    <div className={styles.root}>
      <div className={styles.header_wrapper}>
        <div className={styles.logo_img_section}>
          <img className={styles.logo_img} src={logo} alt="logo" />
        </div>
        <div className={styles.title}>How can I help you today?</div>
        {connectionData && connectionData.length === 0 && showConnectionBtn && (
          <div className={styles.connection_banner}>
            <div className={styles.connection_btn} onClick={onCreateConnection}>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Create your first database connection!
            </div>
          </div>
        )}
      </div>
      <div className={styles.data_content} style={dataContentStyle}>
        <div className={styles.grid_container}>
          {questions.map((obj, index) => (
            <div
              className={styles.grid_item}
              key={index}
              onClick={() => handleClick(obj.question)}
            >
              <div className={styles.example_wrapper}>
                <div className={styles.example_title_container}>
                  <div className={styles.example_title}>{obj.title}</div>
                  <div className={styles.example_sub_title}>{obj.subtitle}</div>
                </div>
                <ToolTip title={"Click to send"} enterDelay={100} placement="top">
                  <span>
                    <IoMdArrowRoundUp className={styles.send_icon} />
                  </span>
                </ToolTip>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SplashScreen;
