import { createSelector } from 'reselect';
import { ICoreState, RootState } from '../../../models/store/store-models';
import { Profile } from 'models/profile';

export const selectCoreSlice = (state: RootState): ICoreState => state.core;

export const selectUser = createSelector(
  selectCoreSlice,
  (state: ICoreState) => state.user,
);

export const selectUserInitials = createSelector(
  selectUser,
  (user: Profile | undefined) => {
    if (user) {
      return `${user.firstName.charAt(0)}${user.lastName.charAt(0)}`;
    }
    return 'M';
  },
);

export const selectSettings = createSelector(
  selectCoreSlice,
  (state: ICoreState) => state.settings,
);

export const selectShowSettingsModal = createSelector(
  selectCoreSlice,
  (state: ICoreState) => state.showSettingsModal,
);
