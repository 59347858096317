export interface Settings {
    'openai-key': OpenAISetting | undefined,
}

export interface OpenAISetting {
    enabled: boolean,
    apiKey: string,
    azureEndpoint: string,
    type: OpenAITypes
}

export enum OpenAITypes {
    OPENAI = 'Open AI',
    AZURE_OPENAI = 'Azure OpenAI'
}