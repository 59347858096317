import React from 'react';

import App from 'containers/app';
import { Provider } from 'react-redux';
import getStore from 'store/configureStore';
import { Router as LibRouter } from 'react-router-dom';
import { createMemoryHistory } from 'history';
import { initialState as initialCoreState } from 'store/slices/core/slice';
import {
  withAITracking,
  ReactPlugin,
  AppInsightsContext,
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import '../../index.css';

export const reactPlugin = new ReactPlugin();
export const browserHistory = createMemoryHistory();
export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_AZURE_INSIGHTS_KEY,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
    autoTrackPageVisitTime: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    extensionConfig: {
      [reactPlugin.identifier]: { history: browserHistory },
    },
  },
});
appInsights.loadAppInsights();

const CustomRouter = ({ basename, children, history }: any) => {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  React.useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <LibRouter
      basename={basename}
      children={children}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

const AppWrapper = () => {
  const store = getStore();

  return (
    <CustomRouter basename="/" history={browserHistory}>
      <AppInsightsContext.Provider value={reactPlugin}>
        <Provider store={store}>
          <App />
        </Provider>
      </AppInsightsContext.Provider>
    </CustomRouter>
  );
};

export default withAITracking(reactPlugin, AppWrapper);
