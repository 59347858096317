import { memo, useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import styles from './entity-node.module.css';

// REDUX STORE
import { useDispatch } from 'react-redux';
import { setQuickInsertText } from 'store/slices/core/slice';

import { ITableData } from '../types';

type EntityNodeProps = {
  data: {
    table: ITableData;
    relatedNodes: ITableData[];
  };
};

function EntityNode({ data: { table, relatedNodes = [] } }: EntityNodeProps) {
  const dispatch = useDispatch();

  const onDoubleClick = useCallback((title: string) => {
    dispatch(setQuickInsertText(title));
  }, []);

  return (
    <>
      <div className={styles.root}>
        <div
          className={styles.header}
          onDoubleClick={() => onDoubleClick(table.name)}
        >
          <p className={styles.title}>{table.name}</p>
        </div>
        <div className={styles.body}>
          <table className={styles.table}>
            <tbody>
              {/* <tr className={styles.tr}>
              <td className={`${styles.td} ${styles.head}`}>customerID</td>
              <td className={`${styles.td} ${styles.head}`}>Int</td>
            </tr> */}
              {table.columns.map((column: string, index: number) => {
                return (
                  <tr
                    className={styles.tr}
                    key={index}
                    onDoubleClick={() => onDoubleClick(column)}
                  >
                    <td
                      className={`${styles.td}  ${index === 0 && styles.head}`}
                    >
                      {column}
                    </td>
                    <td
                      className={`${styles.td}  ${index === 0 && styles.head}`}
                    >
                      -
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <Handle type="target" position={Position.Left} />
      {!!relatedNodes.length && (
        <Handle type="source" position={Position.Right} />
      )}
    </>
  );
}

export default memo(EntityNode);
