import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import styles from './select-field.module.css'

interface Props {
    label?: string;
    placeholder?: string;
    value?: any;
    onChange?: (value: any) => void;
    options?: any;
    defaultValue?: any;
    disabled?: boolean
}

const SelectField = ({ label, placeholder, value, onChange, options, defaultValue, disabled}: Props) => {

    const handleChange = (event: SelectChangeEvent) => {
        onChange?.(event.target.value as string);
    };

    return (
        <div>
            {label && <div className={styles.label}>{label}</div>}

            <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        label=""
                        defaultValue={defaultValue}
                        className={`${styles.input_wrap} ${disabled ? styles.disabled_input:''}`}
                        placeholder={placeholder}
                        onChange={handleChange}
                        disabled={disabled}
                    >
                        {options?.map((obj: any) => <MenuItem className={styles.options} key={obj.value} value={obj.value}>{obj.label}</MenuItem>)}
                    </Select>
                </FormControl>
            </Box>
        </div>
    );
}


export default SelectField