import { Alert, Snackbar } from "@mui/material"
import { selectCoreSlice } from "store/slices/core/selectors";
import { useSelector, useDispatch } from 'react-redux';
import { setToastData } from "store/slices/core/slice";

const Notification = () => {
    const toastData = useSelector(selectCoreSlice).toastData
    const { open, message, type } = toastData
    const dispatch = useDispatch()

    let vertical: any = "top"
    let horizontal: any = 'right'

    const handleClose = () => {
        dispatch(setToastData({ open: false }))
    }

    return (
        <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={type || 'info'} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )

}

export default Notification