import React, { useState } from 'react';
import styles from './question-slider.module.css';
import { CiSaveUp2 } from 'react-icons/ci';
import { personaliseQuestions } from 'constant';
import { deepClone } from '@mui/x-data-grid/utils/utils';
import useSignalrConnection from 'utils/hooks/useSignalrConnection';
import { PreferencesProfile } from 'models/profile';
import { useDispatch } from 'react-redux';
import { setPreferencesProfile } from 'store/slices/main/slice';
import Placeholder from 'components/placeholder-loader';

type props = {
  loading?: boolean;
  profile?: PreferencesProfile;
  onClose?: () => void;
  setProfileLoading?: (value: boolean) => void;
};

interface StateType {
  currentIndex: number;
  selectedOptions: { [key: string]: any };
  showSlider: boolean;
  showDifference: boolean;
  lastSliderIndex: number;
}

const QuestionSlider = ({
  loading,
  profile,
  onClose,
  setProfileLoading,
}: props) => {
  const dispatch = useDispatch();
  const { connection } = useSignalrConnection();
  const [state, setState] = useState<StateType>({
    currentIndex: 0,
    selectedOptions: {},
    showSlider: false,
    showDifference: false,
    lastSliderIndex: personaliseQuestions.length - 1,
  });

  const [showQuestions, setShowQuestions] = useState(false);
  const handleCancel = () => {
    setState({
      currentIndex: 0,
      selectedOptions: {},
      showSlider: false,
      showDifference: false,
      lastSliderIndex: personaliseQuestions.length - 1,
    });
    setShowQuestions(false);
  };

  const currentSlide = personaliseQuestions[state.currentIndex];
  const handleSubmit = async () => {
    try {
      await connection?.invoke('updateProfile', state.selectedOptions);
      dispatch(setPreferencesProfile(undefined));
      if (setProfileLoading) {
        setProfileLoading(true);
      }
    } catch (error) {
      console.log('updateProfile error:', error);
    }
    setState({
      currentIndex: 0,
      selectedOptions: {},
      showSlider: false,
      showDifference: false,
      lastSliderIndex: personaliseQuestions.length - 1,
    });
    setShowQuestions(false);
  };

  const handleButtonClick = (value: string) => {
    setState((prevState) => {
      const data = deepClone(prevState);
      let selectedOptions = data.selectedOptions[currentSlide.attribute] || [];
      const index = selectedOptions.indexOf(value);

      const newSelectedOptions =
        index !== -1
          ? selectedOptions.filter((option: string) => option !== value)
          : [...selectedOptions, value];

      data.selectedOptions[currentSlide.attribute] = newSelectedOptions;

      return data;
    });
  };

  const handleDotClick = (index: number) => {
    setState((prevState) => ({ ...prevState, currentIndex: index }));
  };

  const handleSkipQuestion = () => {
    setState((prevState) => ({
      ...prevState,
      currentIndex: prevState.currentIndex + 1,
    }));
  };

  if (loading) {
    return (
      <div className={styles.slider_container}>
        <div className={styles.grid_loading}>
          <div style={{ height: '50px', width: '50%' }}>
            <Placeholder isDarkBackground></Placeholder>
          </div>
          <div style={{ height: '50px', width: '50%', paddingLeft: '16px' }}>
            <Placeholder isDarkBackground></Placeholder>
          </div>
          <div style={{ height: '50px', width: '100%', paddingLeft: '16px' }}>
            <Placeholder isDarkBackground></Placeholder>
          </div>
          <div style={{ height: '50px', width: '100%', paddingLeft: '16px' }}>
            <Placeholder isDarkBackground></Placeholder>
          </div>
          <div style={{ height: '50px', width: '100%', paddingLeft: '16px' }}>
            <Placeholder isDarkBackground></Placeholder>
          </div>
          <div style={{ height: '50px', width: '300px', paddingLeft: '16px' }}>
            <Placeholder isDarkBackground></Placeholder>
          </div>
        </div>
      </div>
    );
  }

  if (profile && !showQuestions) {
    return <Answers profile={profile} setShowQuestions={setShowQuestions} />;
  }

  return (
    <div className={styles.slider_container}>
      <h1>Tell us about a bit yourself</h1>
      <p>This helps us personalize your experience.</p>

      <span className={styles.dots_container}>
        {personaliseQuestions.map((slide, index) => (
          <span
            key={index}
            className={
              index === state.currentIndex
                ? `${styles.dot} ${styles.active}`
                : styles.dot
            }
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </span>

      <div className={styles.team_sec}>
        <div className={styles.slide_content}>
          <h3>{currentSlide.title}</h3>

          <div className={styles.team_tags}>
            {currentSlide.value.map((item, index) => (
              <button
                key={index}
                className={`${styles.button} ${
                  state.selectedOptions[currentSlide.attribute]?.includes(item)
                    ? styles.selectedButton
                    : ''
                }`}
                onClick={() => handleButtonClick(item)}
              >
                {item}
              </button>
            ))}
          </div>

          <button className={styles.skipbtnLeft} onClick={handleCancel}>
            Cancel
          </button>

          <button
            className={styles.skipbtn}
            onClick={
              state.currentIndex === state.lastSliderIndex
                ? handleSubmit
                : handleSkipQuestion
            }
          >
            {state.currentIndex === state.lastSliderIndex
              ? 'Submit'
              : 'Continue'}
          </button>
        </div>
      </div>

      {/* Privacy Policy */}
      <p className={styles.textColor}>
        We keep your info safe in accordance with our &nbsp;
        <span className={styles.privacy}>
          privacy policy. <CiSaveUp2 />
        </span>
      </p>
    </div>
  );
};

const Answers = ({
  profile,
  setShowQuestions,
}: {
  profile: PreferencesProfile;
  setShowQuestions: (value: boolean) => void;
}) => {
  const handleShow = () => {
    setShowQuestions(true);
  };
  return (
    <div className={styles.answers_wrapper}>
      <h1 className={styles.header_answers}>Selected Preferences</h1>
      <div className={styles.subtext_answers}>
        These preferences help specialize Lumis towards your needs.
      </div>
      <div className={styles.specializations}>
        <div>
          <div className={styles.header_titles}>Areas To Specialize</div>
          <div>
            {profile.AreasOfHelp.map((value, index) => (
              <button
                key={index}
                className={`${styles.selected_known_buttons}`}
              >
                {value}
              </button>
            ))}
          </div>
        </div>
        <div>
          <div className={styles.header_titles}>Reasons</div>
          <div>
            {profile.Reason.map((value, index) => (
              <button
                key={index}
                className={`${styles.selected_known_buttons}`}
              >
                {value}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className={styles.updatePreferencesWrapper}>
        <button className={styles.updatePreferencesButton} onClick={handleShow}>
          Update Preferences
        </button>
      </div>
    </div>
  );
};

export default QuestionSlider;
