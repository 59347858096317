import React, { useCallback } from 'react';
import { FiDownload } from 'react-icons/fi';
import { NavSvg } from 'images/Svgs';
import styles from './header.module.css';

interface HeaderProps {
  isMobile: boolean;
  isMessages: boolean;
  handleSave: () => void;
  toggleCollapse: (value: any) => void;
}

const Header: React.FC<HeaderProps> = ({ isMobile, isMessages, handleSave, toggleCollapse }) => {
  const renderHeader = useCallback(() => {
    return (
      <>
        {isMobile && (
          <div className={styles.header_mobile}>
            <NavSvg className={styles.nav_img} onClick={toggleCollapse} />
          </div>
        )}
        <div className={styles.header}>
          {isMessages && (
            <button className={styles.download_btn} onClick={handleSave}>
              <FiDownload size={20} />
            </button>
          )}
        </div>
      </>
    );
  }, [isMobile, isMessages, handleSave, toggleCollapse]);

  return renderHeader();
};

export default Header;
