import HttpClient from 'api/setup/base-client';
import { OpenAISetting, Settings } from 'models/settings';

interface SettingsDto {
  name: string;
  value: any;
}

export default class SettingsService {
  settings: string = 'settings';
  httpClient: HttpClient;
  constructor(httpClient: HttpClient) {
    this.httpClient = httpClient;
  }

  getConfig = async () => {
    try {
      const res: { [key: string]: any } = await (this.httpClient.get(this.settings));
      const openai = res['openai-key'];
      const settings: Settings = {
        'openai-key': openai
      };
      return {data:settings, isSuccessful: true};
    } catch (e) {
      console.log(e);
      return null;
    }
  };
  
  updateOpenAIKey = async (value: OpenAISetting) => {
    try {
      const body: SettingsDto = {
        name:'openai-key',
        value: value
      };
      await (this.httpClient.put(this.settings, body));
      return true;
    } catch(e) {
      return false;
    }
  }
}
