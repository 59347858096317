import { useRef, useEffect } from 'react';
import styles from './welcome-modal.module.css'
import { ReactComponent as Logo } from "./welcome.svg";
import { useState } from 'react';
import ReactMarkdown from 'react-markdown'
import { termsAndConditions } from 'constant';
import { useDispatch, useSelector } from 'react-redux';
import { setShowTCModal, setToastData } from 'store/slices/core/slice';
import { getErrorMessage, isShowTCModal } from 'utils/common';
import { acceptTC } from 'service';
import OnBoardingModal from 'components/onboarding-modal';

const WelcomeModal = () => {
    const [isShowModal, setIsShowModal] = useState(false)
    const [isShowTerms, setIsShowTerms] = useState(false)
    const [isOnBoarding, setIsOnBoarding] = useState(true)
    const [enabled, setEnabled] = useState(false);
    const listInnerRef = useRef<HTMLDivElement>(null);
    const { showTCModal } = useSelector((state: any) => state.core)
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false)

    const handleScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

            if (scrollTop + clientHeight + 1 >= scrollHeight) {
                if (!enabled) {
                    setEnabled(true)
                }
            }
        }
    };

    useEffect(() => {
        if (isShowTerms && listInnerRef?.current && !enabled) {
            const { scrollHeight, clientHeight } = listInnerRef?.current;
            if (clientHeight === scrollHeight) {
                setEnabled(true)
            }
        }

    }, [isShowTerms, enabled])

    useEffect(() => {
        let checkModal = isShowTCModal()
        if (checkModal) {
            if (showTCModal) {
                setIsShowModal(true)
            } else {

                let showModal = sessionStorage.getItem('isShowTC')
                if (showModal !== 'false') {
                    setIsShowModal(true)
                }

            }
        }
    }, [showTCModal])

    const onContinue = () => {
        setIsShowTerms(true)
    }

    const handleAccept = () => {
        if (!loading) {
            setLoading(true)
            acceptTC(
                {},
                (data: any) => {
                    sessionStorage.setItem('isShowTC', 'false')
                    dispatch(setShowTCModal(false))
                    setIsShowModal(false)
                    setLoading(false)
                },
                (err: any) => {
                    console.log(err);
                    setLoading(false)
                    let errorMessage = getErrorMessage(err)
                    dispatch(setToastData({ open: true, message: errorMessage, type: "error" }))
                }
            )
        }
    }


    if (!isShowModal) {
        return <></>
    }

    if(isOnBoarding){
        return  <OnBoardingModal onClose={setIsOnBoarding}/>
    }

    return (
        <div className={styles.popup}>
            <div className={styles.popup_inner} >
                {isShowTerms ?
                    <div>
                        <div className={styles.header_content}>Terms of Service</div>
                        <div className={styles.main_content} ref={listInnerRef} onScroll={handleScroll}>
                            <ReactMarkdown children={termsAndConditions || ''} />
                        </div>
                        <div className={styles.footer}> <button className={styles.accept_button} disabled={!enabled} onClick={handleAccept}>{loading ? <div className={styles.loader}></div> : 'Accept'} </button> </div>

                    </div>
                    :
                    <>
                        {/* Header */}
                        <div className={styles.header}>
                            Welcome!
                        </div>

                        {/* Main content */}
                        <div className={styles.child_content}>
                            <div className={styles.description}>To get started, Please complete </div>
                            <div className={styles.description}> the terms and conditions</div>
                            <div className={styles.welcome_icon}><Logo /></div>
                        </div>

                        {/* Bottom buttons */}
                        <div className={styles.button_content}>
                            <button className={styles.submit_btn} onClick={onContinue}>Continue</button>
                        </div>
                    </>
                }
            </div>
        </div>
    )

}

export default WelcomeModal