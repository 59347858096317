import React, { FC, useMemo } from 'react';
import styles from './Sidebar.module.css';
import { AiOutlinePlusCircle } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import {
  setConnectionData,
  setMetadata,
  setShowConnectionBtn,
  setShowConnectionModal,
  setToastData,
} from 'store/slices/core/slice';
import { selectCoreSlice } from 'store/slices/core/selectors';
import { Footer } from 'components/sidebar/Footer/Footer';
import { NavigationItem } from '../../../components/sidebar/navigationItem/NavigationItem';
import { v4 as uuid } from 'uuid';
import { DATABASE_KEY, TABLE_KEY } from 'constant';
import { CONNECTION_KEY } from '../../../constant/index';
import { deleteConnection, getAllConnections } from 'service';
import DeleteConfirmation from 'components/delete-confirmation';
import { filterDataBysearch, getErrorMessage } from 'utils/common';
import { useCallback } from 'react';
import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js';
import Logo from 'images/logo_main.svg';
import Loader from 'components/loader';
import Popover from 'components/popover';
import { BsDatabaseFillAdd, BsDatabaseCheck } from 'react-icons/bs';
import useResponsive from 'utils/hooks/useResponsive';
import ToolTip from 'components/tooltip';
import Placeholder from 'components/placeholder-loader';

interface sidebarProps {
  isCollapsed: boolean;
  setIsCollapsed: (value: boolean) => void;
}

export const Sidebar: FC<sidebarProps> = ({ isCollapsed, setIsCollapsed }) => {
  const sessionID: any = uuid();
  const messageId: string = uuid();

  // This state controls the sidebar body visibility
  // const isPinned = useSelector(selectCoreSlice).sidebarPinned;
  const dispatch = useDispatch();
  const connectionData = useSelector(selectCoreSlice).connectionData;
  const isLightMode = useSelector(selectCoreSlice).isLightMode;
  const showConnectionBtn = useSelector(selectCoreSlice).showConnectionBtn;
  const metadata = useSelector(selectCoreSlice).metadata;
  const [loading, setLoading] = React.useState(false);
  const [connectionId, setConnectonId] = React.useState('');
  const [isOpen, setIsOpen] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState<any>({});
  const { showConnectionModal } = useSelector((state: any) => state.core);
  const [searchData, setSearchData] = React.useState<any>([]);
  const [searchValue, setSearchValue] = React.useState('');
  const [showFirstLoad, setShowFirstLoad] = React.useState(true);

  const appInsights = useAppInsightsContext();
  const trackOpenCreateConnectionModal = useTrackMetric(
    appInsights,
    'CREATE-CONNECTION-MODAL-open',
  );

  // This handles the sidebar onblur event display
  const sidebarRef = React.useRef(null);
  const { isMobile } = useResponsive();
  const isPinned = !isCollapsed && !isMobile;

  React.useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setLoading(true);
      let data = filterDataBysearch(connectionData, searchValue, 0);
      setSearchData(data.arr);
      setLoading(false);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [connectionData, searchValue]);

  React.useEffect(() => {
    const handleClickOutside = (event: Event) => {
      if (
        sidebarRef.current &&
        // @ts-ignore
        !sidebarRef?.current?.contains(event.target) &&
        !isPinned &&
        !showConnectionModal
      ) {
        setIsCollapsed(true);
      }
    };

    // Clean the event listener if not applicable
    if (!isCollapsed)
      document.addEventListener('click', handleClickOutside, true);
    else document.removeEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [isPinned, isCollapsed, setIsCollapsed, showConnectionModal]);

  const onCreateConnection = () => {
    trackOpenCreateConnectionModal();
    dispatch(setShowConnectionModal(true));
  };

  const handleSelect = (
    index: number,
    key: string,
    value: string,
    isSelected: boolean,
    parentItem?: string,
    subMenuItems?: any,
  ) => {
    //creating metadata by selected fields

    let obj = metadata;

    if (isSelected) {
      //deselect any field and remove metadata

      if (key === CONNECTION_KEY) {
        obj = {};
      } else if (key === DATABASE_KEY) {
        obj = { ...obj, [key]: '' };
      } else if (key === TABLE_KEY) {
        let arr = metadata[key].filter((item: string) => item !== value);
        let columns = { ...obj.columns };
        delete columns[value];
        obj = { ...obj, [key]: arr, columns: columns };
      }
    } else {
      //select any field and add metadata

      if (index !== metadata.index) {
        const { id, type, version } = connectionData?.[index] || {};
        obj = { index: index, [CONNECTION_KEY]: id, type, version };
      }

      if (key === DATABASE_KEY) {
        obj = { ...obj, [key]: value };
      } else if (key === TABLE_KEY) {
        let arr = obj[key] ? [...obj[key]] : [];
        arr.push(value);
        obj = {
          ...obj,
          [key]: arr,
          [DATABASE_KEY]: parentItem,
          columns: { ...obj.columns, [value]: subMenuItems },
        };
      }
    }

    dispatch(setMetadata(obj));
  };

  const handleRefresh = useCallback(() => {
    setLoading(true);
    getAllConnections(
      (data: any) => {
        if (data) {
          if (data && data.length === 0) {
            dispatch(setShowConnectionBtn(true));
          }
          dispatch(setConnectionData(data));
          setLoading(false);
          setShowFirstLoad(false);
        }
      },
      (err: any) => {
        console.log(err);
        setLoading(false);
        setShowFirstLoad(false);
        let errorMessage = getErrorMessage(err);
        dispatch(
          setToastData({ open: true, message: errorMessage, type: 'error' }),
        );
      },
    );
  }, [dispatch]);

  React.useEffect(() => {
    handleRefresh();
  }, [handleRefresh]);

  const handleDelete = () => {
    setIsOpen(false);
    const { id, index } = deleteData;
    setLoading(true);
    setConnectonId(id);
    deleteConnection(
      id,
      (data: any) => {
        if (index === metadata['index']) {
          dispatch(setMetadata({}));
        }
        let arr = [...connectionData];
        arr.splice(index, 1);
        dispatch(setConnectionData(arr));
        dispatch(
          setToastData({
            open: true,
            message: 'Connection deleted successfully',
            type: 'success',
          }),
        );
        setLoading(false);
      },
      (err: any) => {
        console.log(err);
        setLoading(false);
        let errorMessage = getErrorMessage(err);
        dispatch(
          setToastData({ open: true, message: errorMessage, type: 'error' }),
        );
      },
    );
  };

  const handeClose = () => {
    setIsOpen(false);
  };

  const showDeleteConfirmation = (id: string, index: number) => {
    setIsOpen(true);
    setDeleteData({ id, index });
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
  };

  const ArrowButton = useCallback(() => {
    // Custom animation of the pin for collapse and un collapse
    // pin translated to 45 deg based on collapse
    if (isMobile) return null;

    return (
      <ToolTip
        title={isCollapsed ? 'Open sidebar' : 'Close sidebar'}
        enterDelay={100}
        placement="right"
      >
        <div
          className={styles.pin_icon}
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
        >
          <div
            className={`${styles.pin_icon1} ${
              isCollapsed ? styles.collapsed_p1 : styles.p1
            }`}
          ></div>
          <div
            className={`${styles.pin_icon2} ${
              isCollapsed ? styles.collapsed_p2 : styles.p2
            }`}
          ></div>
        </div>
      </ToolTip>
    );
  }, [isCollapsed, isMobile]);

  const LeftNavHeader = useCallback(() => {
    return (
      <div className={styles.left_nav_header}>
        <p className={styles.header_title}>Lumis</p>
        <p className={styles.version_text}>1.0</p>
      </div>
    );
  }, []);

  const isDatabase = useMemo(
    () => connectionData && connectionData.length === 0 && showConnectionBtn,
    [connectionData, showConnectionBtn],
  );

  const Content = useCallback(() => {
    return (
      <div
        className={
          isLightMode
            ? styles.popover_content_light
            : styles.popover_content_dark
        }
      >
        {isDatabase ? (
          <div
            className={
              isLightMode ? styles.popover_item_light : styles.popover_item_dark
            }
            onClick={onCreateConnection}
          >
            <BsDatabaseFillAdd
              color={isLightMode ? 'black' : 'white'}
              size={20}
            />
            <p
              className={
                isLightMode
                  ? styles.content_title_light
                  : styles.content_title_dark
              }
            >
              Connect Database
            </p>
          </div>
        ) : (
          <div
            className={
              isLightMode ? styles.popover_item_light : styles.popover_item_dark
            }
          >
            <BsDatabaseCheck
              color={isLightMode ? 'black' : 'white'}
              size={20}
            />
            <p
              className={
                isLightMode
                  ? styles.content_title_light
                  : styles.content_title_dark
              }
            >
              Database Connected!
            </p>
          </div>
        )}
      </div>
    );
  }, [isDatabase, onCreateConnection]);

  const rootSideMenuStyle: object = React.useMemo(() => {
    if (isMobile) {
      return {
        position: 'absolute',
        zIndex: 100,
        height: '100%',
      };
    }
    return {};
  }, [isMobile]);

  return (
    <div
      ref={sidebarRef}
      className={`${styles.root} ${!isCollapsed && styles.root__pinned} ${
        isCollapsed && styles.collapsed_root
      }  `}
      style={rootSideMenuStyle}
    >
      <div
        className={` ${
          isCollapsed
            ? styles.collapsed_body
            : styles.not_collapsed_body__pinned
        }  ${isMobile && styles['mobile_view_collapsed_' + isCollapsed]}`}
      >
        <div className={styles.header_left_wrapper}>
          <Popover component={<LeftNavHeader />} content={<Content />} />
        </div>
        <ArrowButton />
        <div>
          {!isCollapsed && (
            <>
              <div className={styles.not_collapsed_body_header}>
                <div className={styles.logo_wrapper}>
                  <img className={styles.logo} src={Logo} alt="logo" />
                  <div className={styles.logo_text}>Lumis</div>
                </div>
              </div>

              <div className={styles.database_connections_title}>
                <div>Database Servers</div>
                <button
                  onClick={onCreateConnection}
                  className={styles.add_connection_button}
                >
                  <AiOutlinePlusCircle className={styles.menu_item_icon} />
                  Add
                </button>
              </div>
              <div
                className={`flex_justify_items_start flex_column ${styles.main_content}`}
              >
                {loading && !isCollapsed && (
                  <div className={styles.loading_navigation_item}>
                    <div style={{ height: '25px' }}>
                      <Placeholder isDarkBackground />
                    </div>
                  </div>
                )}
                {searchData.map((item: any, index: number) => (
                  <NavigationItem
                    key={`navigation-item-${index}-${item.title}`}
                    obj={item}
                    index={index}
                    fieldIndex={0}
                    loading={loading}
                    isPinned={isPinned}
                    isCollapsed={isCollapsed}
                    connectionId={connectionId}
                    onSelect={handleSelect}
                    onRefresh={handleRefresh}
                    onDelete={showDeleteConfirmation}
                  />
                ))}
              </div>
            </>
          )}
        </div>
        {!isCollapsed && (
          <Footer
            messageId={messageId}
            sessionID={sessionID}
            isPinned={isPinned}
            isCollapsed={isCollapsed}
          />
        )}
      </div>

      {isOpen && (
        <DeleteConfirmation
          deleteMessage={'Are you sure you want to delete this connection?'}
          handleClose={handeClose}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};
