import Placeholder from 'components/placeholder-loader';
import logo from 'images/logo_main.svg';
import styles from './home-loading.module.css';

export const HomeLoading = () => {
  return (
    <div className={styles.loading_root}>
      <div className={styles.logo_wrapper}>
        <img className={styles.logo} src={logo} alt="logo" />
        <div className={styles.app_name}>
          <div>L</div>
          <div>U</div>
          <div>M</div>
          <div>I</div>
          <div>S</div>
        </div>
      </div>
    </div>
  );
};
