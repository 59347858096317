import { FC, useCallback, useRef } from 'react';
import { AiOutlineClose } from 'react-icons/ai'
import useClickOutside from 'utils/hooks/useClickOutside';
import styles from './delete-confirmation.module.css'

interface Props {
    deleteMessage?: string;
    handleClose: () => void;
    handleDelete: () => void;
}

const DeleteConfirmation: FC<Props> = ({
    deleteMessage,
    handleClose,
    handleDelete
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const onClickOutside = useCallback(() => {
        if (handleClose) {
            handleClose();
        }
    }, [handleClose]);

    useClickOutside(ref, onClickOutside);

    const onSubmit = () => {
        handleDelete()
    }

    return (
        <div className={styles.popup}>
            <div className={styles.popup_inner} ref={ref}>

                {/* Header */}
                <div className={styles.header}>
                    <div>
                        Delete Confirmation
                    </div>
                    <div >
                        <AiOutlineClose className={styles.close_icon} onClick={handleClose} />
                    </div>
                </div>

                <div className={styles.divider} />

                {/* Main content */}
                <div className={styles.child_content}>
                    <div>
                        {deleteMessage}
                    </div>


                </div>

                {/* <div className={styles.divider} /> */}

                {/* Bottom buttons */}
                <div className={styles.button_content}>
                    <button className={styles.cancel_btn} onClick={handleClose}>Cancel</button>
                    <button className={styles.submit_btn} onClick={onSubmit}>Delete</button>
                </div>
            </div>
        </div>
    )

}

export default DeleteConfirmation