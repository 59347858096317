import styles from './dashboard.module.css';
import { Sidebar } from './sidebar/Siderbar';
import MiddleSection from './middle-section';
import { useCallback, useState } from 'react';
import WelcomeModal from 'components/welcome-modal';
import PrivacyPolicyModal from 'components/privacy-policy-modal';
import CreateConnectionModal from 'components/create-connection-modal';
import { selectShowSettingsModal } from 'store/slices/core/selectors';
import { useSelector } from 'react-redux';
import SettingsModal from 'components/settings-modal';
import useResponsive from 'utils/hooks/useResponsive';

const Dashboard = () => {
  const showSettingsModal = useSelector(selectShowSettingsModal);
  const { isMobile } = useResponsive();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(isMobile);

  const toggleCollapse = useCallback(() => {
    setIsCollapsed((old) => !old);
  }, [setIsCollapsed]);

  return (
    <div className={styles.root}>
      <Sidebar isCollapsed={isCollapsed} setIsCollapsed={setIsCollapsed} />
      <div className={styles.wide_middle_section}>
        <MiddleSection toggleCollapse={toggleCollapse} />
      </div>
      {showSettingsModal && <SettingsModal />}
      <WelcomeModal />
      <PrivacyPolicyModal />
      <CreateConnectionModal />
    </div>
  );
};

export default Dashboard;
