import { useEffect, RefObject } from 'react';

const useClickOutside = (
  ref: RefObject<HTMLDivElement>,
  onClickOutside: () => void,
  extraField?: any
) => {
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        if (!extraField || (!document.querySelector('.MuiPopover-root')?.contains(event.target as Node) && !document.querySelector('.MuiSnackbar-root')?.contains(event.target as Node))) {
          onClickOutside();
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClickOutside, extraField]);
};

export default useClickOutside;
