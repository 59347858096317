import { IconButton, InputAdornment, TextField } from '@mui/material'
import { AiOutlineCloseCircle, AiOutlineSearch } from 'react-icons/ai';
import styles from './input-field.module.css'

interface InputProps {
    label?: string;
    placeholder?: string;
    value?: any;
    isError?: boolean;
    helperText?: string;
    isNumber?: boolean;
    isPassword?: boolean;
    autoComplete?: string;
    isSearchField?: boolean;
    onChange?: (value: any) => void;
}

const InputField = ({ label, placeholder, value, onChange, isError, helperText, isNumber, isPassword, autoComplete, isSearchField }: InputProps) => {

    const handleChange = (e: any) => {
        let value = e.target.value
        if (!isNumber || (isNumber && (!value || Number(value)))) {
            onChange?.(value)
        }

    }

    const handleCancel = () => {
        let value = ''
        if (!isNumber || (isNumber && (!value || Number(value)))) {
            onChange?.(value)
        }
    }

    return (
        <div>
            {label && <div className={styles.label}>{label}</div>}

            <TextField
                className={styles.input_wrap}
                id="outlined-error"
                label=""
                type={isPassword ? 'password' : 'text'}
                value={value}
                autoComplete={autoComplete}
                error={isError}
                placeholder={placeholder}
                onChange={handleChange}
                InputProps={isSearchField ? {
                    startAdornment: (
                        <InputAdornment position="start">
                            <AiOutlineSearch className={styles.icon} />
                        </InputAdornment>
                    ),

                    endAdornment: value && (
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleCancel}
                        ><AiOutlineCloseCircle className={styles.icon} /></IconButton>
                    )
                } : {}}
            />

            {isError && <div className={styles.error_text}>{helperText}</div>}
        </div>
    )

}

export default InputField