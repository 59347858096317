import { AUTHORITY_URL, CLIENT_ID } from "config";
import moment from "moment";


// Function to convert the JSON(Array of objects) to CSV.
function arrayToCsv(headers: any, data: any): string {
    const csvRows = [];
    // getting headers. 
    const headerValues = headers.map((header: any) => header.name);
    csvRows.push(headerValues.join(',')); // Push into array as comma separated values
    // Getting rows. 
    for (const row of data) {
        csvRows.push(row.join(',')); // Push into array as comma separated values. 
    }

    return csvRows.join('\n'); // To enter the next rows in the new line '\n' 
};


// Function to download the generated CSV as a .csv file.
const download = (data: any, fileName: string) => {
    const blob = new Blob([data], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', fileName + '.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
};

export const generateCSV = (header: any, data: any, filename: string) => {
    const csvData = arrayToCsv(header, data);
    download(csvData, filename);
};

export const getErrorMessage = (err: any) => {
    let errorMessage = "Ooops something went wrong.Please try again"
    if (err?.response?.data?.errors && Array.isArray(err?.response?.data?.errors) && err?.response?.data?.errors?.[0]?.errorMessage) {
        errorMessage = err?.response?.data?.errors?.[0]?.errorMessage
    } else if (err?.response?.data?.title) {
        errorMessage = err?.response?.data?.title
    }

    return errorMessage
}

export const getAccessToken = () => {
    let data: any = sessionStorage.getItem(`oidc.user:${AUTHORITY_URL}:${CLIENT_ID}`)
    let accessToken = ''
    if (data) {
        data = JSON.parse(data)
        accessToken = 'Bearer ' + (data?.access_token || '')
    }

    return accessToken;
}

export const isShowTCModal = () => {
    let data: any = sessionStorage.getItem(`oidc.user:${AUTHORITY_URL}:${CLIENT_ID}`)
    let isShow = false
    if (data) {
        data = JSON.parse(data)
        isShow = data?.profile?.T_and_C === "True"
    }

    return isShow
}

export const downloadChat = (data: any) => {
    let str = ``;
    const lineBreak = `\n`;
    const userLabel = `User : `;
    const botLabel = 'Assistant : ';
    const insightsLabel = 'Insights : ';
    const sqlLabel = 'SQL : ';
    const tableLabel = 'Table : ';
    const errorLable = 'Error - ';

    data.forEach((obj: any) => {
        str += obj.time + lineBreak;
        if (obj.user) {
            str += userLabel + obj.message + lineBreak;
        } else {
            if (obj.error) {
                str += botLabel + errorLable + obj.message + lineBreak;
            } else {
                str += botLabel + obj.message + lineBreak;

                if (obj.insights) {
                    str += insightsLabel + obj.insights + lineBreak;
                }

                if (obj.command) {
                    str += sqlLabel + obj.command + lineBreak;
                }

                if (obj?.database?.tables?.length > 0) {
                    str +=
                        tableLabel + JSON.stringify(obj?.database?.tables) + lineBreak;
                }
            }
        }
        str += lineBreak;
    });


    const fileData = str;
    const blob = new Blob([fileData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = 'chat ' + moment().format('DD-MM hh:mm') + '.txt';
    link.href = url;
    link.click();
}

export const treeGraphKeys = ['databases', 'tables', 'columns']
export const filterDataBysearch = (data: any, str: string, index: number) => {
    if (str) {
        let arr = []
        let hide = true

        for (let i = 0; i < data.length; i++) {
            let obj = data[i]

            //Check for matching search string in current obj
            if (index === 3 ? obj.toLowerCase().indexOf(str.toLowerCase()) > -1 : obj.name.toLowerCase().indexOf(str.toLowerCase()) > -1) {
                arr.push(obj)
                hide = false
            } else {

                //Check for matching string in children array 
                let newData = obj[treeGraphKeys[index]]
                if (newData && newData.length > 0) {
                    let filtereData: any = filterDataBysearch(newData, str, index + 1)
                    let childArr: any = filtereData.arr
                    if (childArr.length > 0) {
                        arr.push({ ...obj, hide: filtereData.hide, [treeGraphKeys[index]]: childArr })
                        hide = filtereData.hide
                    } else {
                        arr.push({ ...obj, hide: true, [treeGraphKeys[index]]: childArr })
                    }
                } else if (index !== 3) {
                    arr.push({ ...obj, hide: true })

                }
            }

        }
        return { arr, hide }
    } else {
        return { arr: data }
    }
}

export const fetchIntials = (auth:any) => {
    let str = ''
    if (auth.userData?.profile?.first_name && auth.userData?.profile?.first_name !== 'No Name') {
        str += auth.userData?.profile?.first_name.charAt(0)
    }
    if (auth.userData?.profile?.last_name && auth.userData?.profile?.last_name !== 'No Name') {
        str += auth.userData?.profile?.last_name.charAt(0)
    }

    if (str.trim().length === 0) {
        return auth.userData?.profile?.sub.charAt(0)
    }
    return str
}