import { BsColumns, BsFillDatabaseFill, BsFilterLeft, BsTable } from "react-icons/bs"
import { MdOutlineSchema } from "react-icons/md"
import { RiLayoutMasonryFill } from "react-icons/ri"

export const termsAndConditions = `**Terms and Conditions**


**1. Acceptance of Terms**


By accessing and using the software provided by our company ("We", "Us", "Our"), you, the user ("You", "Your"), agree to comply with, and be bound by, the following terms and conditions ("Terms") and any documents referred to herein. Please review these Terms carefully.


**2. Changes to these Terms**


We reserve the right to modify these Terms at any time. It is Your responsibility to check periodically for any changes we may make to these Terms. Your continued use of this software signifies your acceptance of any updated or modified Terms.


**3. License Grant and Restrictions**


We grant You a non-exclusive, non-transferable, revocable license to use our software for personal or internal business use, provided You agree not to modify, distribute, create derivative works of, publicly display, publicly perform, republish or transmit the software.


**4. Confidentiality**


During the course of your use of the software, you may receive information relating to us or to the software that is not known to the general public ("Confidential Information"). You agree not to disclose or use any Confidential Information without our express written consent.


**5. Non-Compete**


You agree that You will not, during the term of this Agreement and for a period of one year following the termination of this Agreement, directly or indirectly engage in any business that competes with us.


**6. Intellectual Property Rights**


All intellectual property rights in the software, including patents, copyrights, trade secrets, trademarks, are owned by us. No ownership or right to use our intellectual property is transferred to You by these Terms.


**7. User Obligations**


You agree not to use the software in a way that may infringe the intellectual property rights of others, or in a way that is illegal, obscene, defamatory, threatening, infringing of intellectual property rights, invasive of privacy, or objectionable.


**8. Data Protection and Privacy**


We will comply with all applicable data protection and privacy laws and regulations in the performance of our obligations under these Terms. Please refer to our Privacy Policy for information on how we collect, use and disclose information from our users.


**9. Limitation of Liability**


To the maximum extent permitted by law, we shall not be liable for any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, good-will, or other intangible losses.


**10. Indemnification**


You agree to indemnify, defend and hold us harmless from and against any claims, liabilities, damages, losses, costs, expenses or fees arising out of or relating to your violation of these Terms or use of the software.


**11. Termination**


We reserve the right to terminate or suspend Your access to the software at any time, without notice, for any reason.


**12. Governing Law**


These Terms shall be governed by and construed in accordance with the laws of the jurisdiction in which our company is registered.


**13. Dispute Resolution**


Any disputes arising out of or relating to these Terms or Your use of the software will be resolved by arbitration, with the proceedings to be held in the city where our company is headquartered.


**14. Severability**


If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions will remain in full force and effect.


By using this software, You acknowledge that You have read these Terms, understand them, and agree to be bound by them. If You do not agree to these Terms, do not access or use the software.


`

export const policies = `**Privacy Policy**


We value the privacy of our users and are committed to protecting their personal information. This Privacy Policy outlines how we collect, use, disclose, and safeguard your information when you use our [website/service/application] (collectively referred to as the "Service"). Please read this policy carefully to understand our practices regarding your information and how we will treat it. By using the Service, you agree to the terms of this Privacy Policy.

Information We Collect
1.1 Personal Information: When you use our Service, we may collect certain personally identifiable information ("Personal Information"), including but not limited to:

Name
Email address
Postal address
Phone number
Payment information

1.2 Non-Personal Information: We may also collect non-personal information, such as demographic information, IP addresses, browser type, and usage patterns when you interact with our Service. This information cannot be used to identify you personally.

How We Use Your Information
We may use the information we collect for various purposes, including:

To provide and maintain the Service
To personalize your experience and improve customer service
To process transactions and fulfill your requests
To send you important notifications and updates
To analyze and improve the Service
To detect, prevent, and address technical issues or fraud
To comply with legal obligations
Information Sharing and Disclosure
We may disclose your personal information to third parties in the following circumstances:

With your consent
To trusted third-party service providers who assist us in operating the Service, conducting our business, or servicing you
When required by law or in response to a valid legal request
To protect our rights, property, or safety, or that of our users or others
In the event of a merger, acquisition, or sale of all or a portion of our assets
Data Security
We implement reasonable security measures to protect your information from unauthorized access, alteration, disclosure, or destruction. However, please note that no method of transmission over the internet or electronic storage is 100% secure, and we cannot guarantee its absolute security.

Your Choices and Rights
You have certain rights regarding the personal information we hold about you. These may include the right to access, correct, update, or delete your information. You may also have the right to object to or restrict certain processing activities. To exercise these rights, please contact us using the information provided at the end of this Privacy Policy.





`

// export const connectionMockData = [
//     {
//         userID: "userEmail@email.com",
//         "connectionName": "Connection list",
//         "databaseConnections": [
//             {
//                 "id": 1,
//                 "name": "Connection1",
//                 "type": "MS SQL",
//                 "version": "12.234.5606",
//                 "server": "example_server1",
//                 "databases": [
//                     {
//                         "name": "Database1",
//                         "schemas": [
//                             {
//                                 "name": "Schema1",
//                                 "tables": [
//                                     {
//                                         "name": "Table1",
//                                         "columns": [
//                                             "Column1",
//                                             "Column2",
//                                             "Column3"
//                                         ],
//                                         "primaryKey": ["Column1"],
//                                         "foreignKey": []
//                                     },
//                                     {
//                                         "name": "Table2",
//                                         "columns": [
//                                             "Column1",
//                                             "Column2",
//                                             "Column3"
//                                         ],
//                                         "primaryKey": ["Column1"],
//                                         "foreignKey": []
//                                     }
//                                 ]
//                             }
//                         ]
//                     }
//                 ]
//             }
//         ]
//     }
// ]

export const connectionMockData = [
    {


        id: 'e4b8e498-f58d-4d0d-8187-e1b45a9c21ef',
        name: 'Test 1',
        type: 'MS SQL',
        version: '12.00.5168',
        server: 'demodb-server.database.windows.net',
        databases: [
            {
                name: 'demo',
                tables: [
                    {
                        name: 'Customer and Suppliers by City',
                        columns: [
                            'City',
                            'CompanyName',
                            'ContactName',
                            'Relationship'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Alphabetical list of products',
                        columns: [
                            'CategoryID',
                            'CategoryName',
                            'Discontinued',
                            'ProductID',
                            'ProductName',
                            'QuantityPerUnit',
                            'ReorderLevel',
                            'SupplierID',
                            'UnitPrice',
                            'UnitsInStock',
                            'UnitsOnOrder'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Current Product List',
                        columns: [
                            'ProductID',
                            'ProductName'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Orders Qry',
                        columns: [
                            'Address',
                            'City',
                            'CompanyName',
                            'Country',
                            'CustomerID',
                            'EmployeeID',
                            'Freight',
                            'OrderDate',
                            'OrderID',
                            'PostalCode',
                            'Region',
                            'RequiredDate',
                            'ShipAddress',
                            'ShipCity',
                            'ShipCountry',
                            'ShipName',
                            'ShippedDate',
                            'ShipPostalCode',
                            'ShipRegion',
                            'ShipVia'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Products Above Average Price',
                        columns: [
                            'ProductName',
                            'UnitPrice'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Products by Category',
                        columns: [
                            'CategoryName',
                            'Discontinued',
                            'ProductName',
                            'QuantityPerUnit',
                            'UnitsInStock'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Quarterly Orders',
                        columns: [
                            'City',
                            'CompanyName',
                            'Country',
                            'CustomerID'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Invoices',
                        columns: [
                            'Address',
                            'City',
                            'Country',
                            'CustomerID',
                            'CustomerName',
                            'Discount',
                            'ExtendedPrice',
                            'Freight',
                            'OrderDate',
                            'OrderID',
                            'PostalCode',
                            'ProductID',
                            'ProductName',
                            'Quantity',
                            'Region',
                            'RequiredDate',
                            'Salesperson',
                            'ShipAddress',
                            'ShipCity',
                            'ShipCountry',
                            'ShipName',
                            'ShippedDate',
                            'ShipperName',
                            'ShipPostalCode',
                            'ShipRegion',
                            'UnitPrice'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Order Details Extended',
                        columns: [
                            'Discount',
                            'ExtendedPrice',
                            'OrderID',
                            'ProductID',
                            'ProductName',
                            'Quantity',
                            'UnitPrice'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Order Subtotals',
                        columns: [
                            'OrderID',
                            'Subtotal'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Product Sales for 1997',
                        columns: [
                            'CategoryName',
                            'ProductName',
                            'ProductSales'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Category Sales for 1997',
                        columns: [
                            'CategoryName',
                            'CategorySales'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Sales by Category',
                        columns: [
                            'CategoryID',
                            'CategoryName',
                            'ProductName',
                            'ProductSales'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Sales Totals by Amount',
                        columns: [
                            'CompanyName',
                            'OrderID',
                            'SaleAmount',
                            'ShippedDate'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Summary of Sales by Quarter',
                        columns: [
                            'OrderID',
                            'ShippedDate',
                            'Subtotal'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Summary of Sales by Year',
                        columns: [
                            'OrderID',
                            'ShippedDate',
                            'Subtotal'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'CustomerCustomerDemo',
                        columns: [
                            'CustomerID',
                            'CustomerID',
                            'CustomerTypeID',
                            'CustomerTypeID'
                        ],
                        primaryKeyColumns: [
                            'CustomerID',
                            'CustomerTypeID'
                        ],
                        foriegnKeyColumns: [
                            'CustomerID',
                            'CustomerTypeID'
                        ]
                    },
                    {
                        name: 'CustomerDemographics',
                        columns: [
                            'CustomerDesc',
                            'CustomerTypeID'
                        ],
                        primaryKeyColumns: [
                            'CustomerTypeID'
                        ],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Region',
                        columns: [
                            'RegionDescription',
                            'RegionID'
                        ],
                        primaryKeyColumns: [
                            'RegionID'
                        ],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Territories',
                        columns: [
                            'RegionID',
                            'TerritoryDescription',
                            'TerritoryID'
                        ],
                        primaryKeyColumns: [
                            'TerritoryID'
                        ],
                        foriegnKeyColumns: [
                            'RegionID'
                        ]
                    },
                    {
                        name: 'EmployeeTerritories',
                        columns: [
                            'EmployeeID',
                            'EmployeeID',
                            'TerritoryID',
                            'TerritoryID'
                        ],
                        primaryKeyColumns: [
                            'EmployeeID',
                            'TerritoryID'
                        ],
                        foriegnKeyColumns: [
                            'EmployeeID',
                            'TerritoryID'
                        ]
                    },
                    {
                        name: 'database_firewall_rules',
                        columns: [
                            'create_date',
                            'end_ip_address',
                            'id',
                            'modify_date',
                            'name',
                            'start_ip_address'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Employees',
                        columns: [
                            'Address',
                            'BirthDate',
                            'City',
                            'Country',
                            'EmployeeID',
                            'Extension',
                            'FirstName',
                            'HireDate',
                            'HomePhone',
                            'LastName',
                            'Notes',
                            'Photo',
                            'PhotoPath',
                            'PostalCode',
                            'Region',
                            'ReportsTo',
                            'Title',
                            'TitleOfCourtesy'
                        ],
                        primaryKeyColumns: [
                            'EmployeeID'
                        ],
                        foriegnKeyColumns: [
                            'ReportsTo'
                        ]
                    },
                    {
                        name: 'Categories',
                        columns: [
                            'CategoryID',
                            'CategoryName',
                            'Description',
                            'Picture'
                        ],
                        primaryKeyColumns: [
                            'CategoryID'
                        ],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Customers',
                        columns: [
                            'Address',
                            'City',
                            'CompanyName',
                            'ContactName',
                            'ContactTitle',
                            'Country',
                            'CustomerID',
                            'Fax',
                            'Phone',
                            'PostalCode',
                            'Region'
                        ],
                        primaryKeyColumns: [
                            'CustomerID'
                        ],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Shippers',
                        columns: [
                            'CompanyName',
                            'Phone',
                            'ShipperID'
                        ],
                        primaryKeyColumns: [
                            'ShipperID'
                        ],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Suppliers',
                        columns: [
                            'Address',
                            'City',
                            'CompanyName',
                            'ContactName',
                            'ContactTitle',
                            'Country',
                            'Fax',
                            'HomePage',
                            'Phone',
                            'PostalCode',
                            'Region',
                            'SupplierID'
                        ],
                        primaryKeyColumns: [
                            'SupplierID'
                        ],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Orders',
                        columns: [
                            'CustomerID',
                            'EmployeeID',
                            'Freight',
                            'OrderDate',
                            'OrderID',
                            'RequiredDate',
                            'ShipAddress',
                            'ShipCity',
                            'ShipCountry',
                            'ShipName',
                            'ShippedDate',
                            'ShipPostalCode',
                            'ShipRegion',
                            'ShipVia'
                        ],
                        primaryKeyColumns: [
                            'OrderID'
                        ],
                        foriegnKeyColumns: [
                            'CustomerID',
                            'EmployeeID',
                            'ShipVia'
                        ]
                    },
                    {
                        name: 'Products',
                        columns: [
                            'CategoryID',
                            'Discontinued',
                            'ProductID',
                            'ProductName',
                            'QuantityPerUnit',
                            'ReorderLevel',
                            'SupplierID',
                            'UnitPrice',
                            'UnitsInStock',
                            'UnitsOnOrder'
                        ],
                        primaryKeyColumns: [
                            'ProductID'
                        ],
                        foriegnKeyColumns: [
                            'CategoryID',
                            'SupplierID'
                        ]
                    },
                    {
                        name: 'Order Details',
                        columns: [
                            'Discount',
                            'OrderID',
                            'OrderID',
                            'ProductID',
                            'ProductID',
                            'Quantity',
                            'UnitPrice'
                        ],
                        primaryKeyColumns: [
                            'OrderID',
                            'ProductID'
                        ],
                        foriegnKeyColumns: [
                            'OrderID',
                            'ProductID'
                        ]
                    }
                ]
            }

        ]
    },
    {
        id: 'e4b8e498-f58d-4d0d-8187-e1b45a9c21efs',
        name: 'Test 2',
        type: 'MS SQL',
        version: '12.00.5168',
        server: 'demodb-server.database.windows.net',
        databases: [
            {
                name: 'demo 2',
                tables: [
                    {
                        name: 'Customer and Suppliers by City 2',
                        columns: [
                            'City',
                            'CompanyName',
                            'ContactName',
                            'Relationship'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Alphabetical list of products 2',
                        columns: [
                            'CategoryID',
                            'CategoryName',
                            'Discontinued',
                            'ProductID',
                            'ProductName',
                            'QuantityPerUnit',
                            'ReorderLevel',
                            'SupplierID',
                            'UnitPrice',
                            'UnitsInStock',
                            'UnitsOnOrder'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Current Product List 2',
                        columns: [
                            'ProductID',
                            'ProductName'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Orders Qry 2',
                        columns: [
                            'Address',
                            'City',
                            'CompanyName',
                            'Country',
                            'CustomerID',
                            'EmployeeID',
                            'Freight',
                            'OrderDate',
                            'OrderID',
                            'PostalCode',
                            'Region',
                            'RequiredDate',
                            'ShipAddress',
                            'ShipCity',
                            'ShipCountry',
                            'ShipName',
                            'ShippedDate',
                            'ShipPostalCode',
                            'ShipRegion',
                            'ShipVia'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    },
                    {
                        name: 'Products Above Average Price 2',
                        columns: [
                            'ProductName',
                            'UnitPrice'
                        ],
                        primaryKeyColumns: [],
                        foriegnKeyColumns: []
                    }
                ]
            }

        ]
    }
]



export const fields: any = [
    {
        field: 'name',
        subItem: 'databases',
        icon: RiLayoutMasonryFill,
        key: 'connection'
    },
    {
        field: 'name',
        subItem: 'tables',
        icon: BsFillDatabaseFill,
        key: 'databaseType'
    },
    // {
    //   field: 'name',
    //   subItem: 'schemas',
    //   icon: BsFillDatabaseFill
    // },
    {
        field: 'name',
        subItem: 'columns',
        icon: BsTable,
        key: 'Table(s) in the database and their columns'
    },
    {
        subItem: 'columns',
        icon: BsColumns,
        key: 'columns'
    }
]

export const defaultFields: any = [
    {
        field: 'name',
        subItem: 'databases',
        icon: RiLayoutMasonryFill,
        key: 'connection'
    },
    {
        field: 'name',
        subItem: 'tables',
        icon: MdOutlineSchema,
        key: 'databaseType'
    },
    {
        field: 'name',
        subItem: 'columns',
        icon: BsFilterLeft,
        key: 'Table(s) in the database and their columns'
    }
]

export const personaliseQuestions = [
    {
      title: 'What brings you here today?',
      attribute: "Reason",
      value: ['Work', 'Personal', 'School', 'Nonprofit'],
    },
    {
      title: 'What area(s) can Lumis help with?',
      attribute: "AreasOfHelp",
      value: [
        'Software development',
        'HR/Recruting',
        'IT',
        'Sales and CRM',
        'construction',
        'NonProfit',
        'Operations',
        'Product Management',
        'Legal',
        'Design and Creative',
        'Education',
        'PMO',
        'Marketing',
        'Other',
      ],
    },
    {
      title: 'One last question, how did you hear about us?',
      attribute: "HowDidYouHearAboutUs",
      value: [
        'Search Engine',
        'Linkedin',
        'consultant',
        'Friend/Collegue',
        'Audio Ad',
        'Social Media',
        'TV/Streaming service',
        'Youtube Ad',
        'Software review sites',
        'Other',
      ],
    },
  ];

export const CONNECTION_KEY = 'connection'
export const DATABASE_KEY = 'databaseType'
export const TABLE_KEY = 'Table(s) in the database and their columns'
export const DATABASE_TYPE_KEY = 'type'
export const VERSION_KEY = 'version'

