import { useRef, useEffect, useCallback } from 'react';
import styles from './create-connection-modal.module.css'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setConnectionData, setShowConnectionModal, setToastData } from 'store/slices/core/slice';
import useClickOutside from 'utils/hooks/useClickOutside';
import { AiOutlineClose, AiOutlineInfoCircle } from 'react-icons/ai';
import InputField from 'components/input-field';
import SelectField from 'components/select-field';
import CheckboxField from 'components/checkbox-field';
import { getConnection } from 'service';
import { selectCoreSlice } from 'store/slices/core/selectors';
import { getErrorMessage } from 'utils/common';
import {
    useAppInsightsContext,
    useTrackMetric,
} from '@microsoft/applicationinsights-react-js';

const databaseTypes = [{ label: 'Microsoft SQL Server', value: 'MSSQL' }, { label: 'MySQL', value: 'MySQL' }]


interface defaultValues {
    type: string;
    port: string;
    isSslEnabled: boolean;
    name: string;
    host: string;
    username: string;
    password: string;
}

const CreateConnectionModal = () => {
    const [data, setData] = useState<any>({})
    const [isShowModal, setIsShowModal] = useState(false)
    const [enabled, setEnabled] = useState(false);
    const listInnerRef = useRef<HTMLDivElement>(null);
    const { showConnectionModal } = useSelector((state: any) => state.core)
    const dispatch = useDispatch();
    const [isShowError, setIsShowError] = useState(false)
    const ref = useRef<HTMLDivElement>(null);
    const [loading, setLoading] = useState(false)
    const connectionData = useSelector(selectCoreSlice).connectionData

    const appInsights = useAppInsightsContext();
    const trackCloseCreateConnectionModal = useTrackMetric(appInsights, "CREATE-CONNECTION-MODAL-close")
    const trackSubmitCreationDbConnection = useTrackMetric(appInsights, "DB-CONNECTION-submit-creation")

    useEffect(() => {
        if (listInnerRef?.current && !enabled) {
            const { scrollHeight, clientHeight } = listInnerRef?.current;
            if (clientHeight === scrollHeight) {
                setEnabled(true)
            }
        }

    }, [enabled])

    useEffect(() => {
        if (showConnectionModal) {
            const defaultValues: defaultValues = { type: 'MSSQL', port: '1433', isSslEnabled: true, name: '', host: '', username: '', password: '' }
            setData(defaultValues)
            setIsShowModal(true)
        }

    }, [showConnectionModal])

    const handleClose = useCallback(() => {
        dispatch(setShowConnectionModal(false))
        setIsShowModal(false)
    }, [dispatch])

    const onClickOutside = useCallback(() => {
        trackCloseCreateConnectionModal()
        handleClose()
    }, [handleClose]);

    useClickOutside(ref, onClickOutside, true);

    const handleChange = (label: string, value: any, checkForPort?: boolean) => {
        if (checkForPort) {
            setData({ ...data, [label]: value, port: value === "MSSQL" ? 1433 : 3306 })
        } else {
            setData({ ...data, [label]: value })
        }
    }

    const handleSubmit = () => {

        if (loading) {
            return
        }
        trackSubmitCreationDbConnection();
        let fields = ['name', 'host', 'port', 'username', 'password',]
        let isError = false
        if (fields) {
            fields.forEach((obj) => {
                if (!data[obj] || data[obj]?.trim?.().length === 0) {
                    setIsShowError(true)
                    isError = true
                }
            })
        }

        if (!isError) {
            setLoading(true)
            let body: any = { ...data, port: Number(data.port) }

            getConnection(
                body,
                (data: any) => {
                    dispatch(setToastData({ open: true, message: "Connection established successfully", type: "success" }))
                    let dataArr = connectionData.concat(data.databaseConnections || [])
                    dispatch(setConnectionData(dataArr))
                    dispatch(setShowConnectionModal(false))
                    setIsShowModal(false)
                    setLoading(false)
                },
                (err: any) => {
                    console.log(err);
                    setLoading(false)
                    let errorMessage = getErrorMessage(err)
                    dispatch(setToastData({ open: true, message: errorMessage, type: "error" }))
                }
            )
        }
    }

    const checkIsError = (value: any) => {
        if (isShowError && (!value || value?.trim()?.length === 0)) {
            return true
        }
        return false
    }

    if (!isShowModal) {
        return <></>
    }

    const { type, name, host, port, username, password, isSslEnabled } = data
    return (

        <div className={styles.popup}>
            <div className={styles.popup_inner} ref={ref}>

                <div className={styles.header}>
                    <div>
                        Create Connection
                    </div>
                    <div >
                        <AiOutlineClose className={styles.close_icon} onClick={handleClose} />
                    </div>
                </div>

                <div className={styles.banner}>
                    <AiOutlineInfoCircle />
                    Connection settings are stored encrypted.
                </div>

                <div className={styles.field_container}>
                    <SelectField
                        label='Database type'
                        placeholder='Database type'
                        defaultValue='MySQL'
                        options={databaseTypes}
                        value={type}
                        disabled={false}
                        onChange={(e) => handleChange('type', e, true)}
                    />

                    <InputField
                        label='Name'
                        placeholder='Name'
                        value={name}
                        isError={checkIsError(name)}
                        helperText={'Please enter name'}
                        onChange={(e) => handleChange('name', e)}
                    />

                    <InputField
                        label='Host'
                        placeholder='Connection host'
                        value={host}
                        isError={checkIsError(host)}
                        helperText={'Please enter host'}
                        onChange={(e) => handleChange('host', e)}
                    />

                    <InputField
                        label='Port'
                        placeholder='Port'
                        value={port}
                        isError={checkIsError(port)}
                        helperText={'Please enter port number'}
                        onChange={(e) => handleChange('port', e)}
                        isNumber={true}
                    />

                    <InputField
                        label='Username'
                        placeholder='Connection username'
                        value={username}
                        autoComplete={'username'}
                        isError={checkIsError(username)}
                        helperText={'Please enter username'}
                        onChange={(e) => handleChange('username', e)}
                    />

                    <InputField
                        label='Password'
                        placeholder='Password'
                        value={password}
                        autoComplete={'password'}
                        isPassword={true}
                        isError={checkIsError(password)}
                        helperText={'Please enter password'}
                        onChange={(e) => handleChange('password', e)}
                    />

                    <CheckboxField
                        label='SSL'
                        value={isSslEnabled}
                        onChange={(e) => handleChange('isSslEnabled', e)}
                    />
                </div>

                <div className={styles.button_content}>
                    <button className={styles.submit_btn} onClick={handleSubmit}>
                        {loading ? <div className={styles.loader}></div> : 'Submit'}
                    </button>
                </div>
            </div>
        </div>

    )

}

export default CreateConnectionModal