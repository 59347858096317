// REDUX STORE
import { useSelector } from 'react-redux';
import { selectCoreSlice } from 'store/slices/core/selectors';
import Database from './database';
import { IDatabaseInfo } from 'models/store/store-models';

const DatabaseContext = (props: any) => {
  const connectionData: IDatabaseInfo[] =
    useSelector(selectCoreSlice).connectionData;

  return (
    <>
      {connectionData.map((connection: IDatabaseInfo, index: number) => {
        return <Database connection={connection} key={index} {...props}/>;
      })}
    </>
  );
};

export default DatabaseContext;
