import { useState, useMemo, Fragment } from 'react';
import styles from './NavigationItem.module.css';

// ICONS
import { BiChevronDown, BiChevronLeft } from 'react-icons/bi';
import { BsColumns } from 'react-icons/bs';

import { useSelector, useDispatch } from 'react-redux';
import { selectCoreSlice } from 'store/slices/core/selectors';
import { defaultFields, fields, TABLE_KEY } from 'constant';
import { CONNECTION_KEY } from '../../../constant/index';
import { MdDeleteOutline, MdOutlineRefresh } from 'react-icons/md';
import Loader from 'components/loader';
import { Tooltip } from '@mui/material';
import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js';
import { setQuickInsertText } from 'store/slices/core/slice';
import useClickPreventionOnDoubleClick from 'utils/hooks/useClickPreventionOnDoubleClick';

export type NavigationItemParams = {
  index?: number;
  isCollapsed?: boolean;
  isPinned?: boolean;
  databaseConnections?: any;
  obj?: any;
  fieldIndex?: number;
  isStatic?: boolean;
  connectionId?: string;
  loading?: boolean;
  parentItem?: string;
  onRefresh?: (value: any) => void;
  onDelete?: (value: any, index: number) => void;
  onSelect?: (
    index: number,
    key: string,
    value: string,
    checkIsSelected: boolean,
    parentItem?: string,
    subMenuItems?: any,
  ) => void;
};

export const NavigationItem = ({
  fieldIndex = 0,
  index = 0,
  isCollapsed = false,
  isPinned = false,
  obj,
  isStatic,
  loading,
  onSelect,
  onRefresh,
  onDelete,
  parentItem,
}: NavigationItemParams) => {
  const [isOpenSubmenu, setIsOpenSubmenu] = useState<boolean>();
  const metadata = useSelector(selectCoreSlice).metadata;
  const dispatch = useDispatch();

  // ICON BUTTONS
  const leftArrowButton = <BiChevronLeft className={`arrow_button`} />;
  const downArrowButton = <BiChevronDown className={`arrow_button`} />;

  // Getting title and all things from obj by index
  const fieldsArr = isStatic ? defaultFields : fields;
  const title = fieldsArr?.[fieldIndex]?.field
    ? obj[fieldsArr[fieldIndex].field]
    : typeof obj === 'string'
    ? obj
    : '';
  const subMenuItems = fieldsArr?.[fieldIndex]?.subItem
    ? obj[fieldsArr[fieldIndex].subItem]
    : [];
  const Icon = fieldsArr?.[fieldIndex]?.icon
    ? fieldsArr?.[fieldIndex]?.icon
    : BsColumns;
  const key = fieldsArr[fieldIndex].key;

  const appInsights = useAppInsightsContext();
  const trackDeleteDbConnection = useTrackMetric(
    appInsights,
    'DB-CONNECTION-delete-connection',
  );
  const trackRefreshDbConnection = useTrackMetric(
    appInsights,
    'DB-CONNECTION-refresh-connection',
  );

  const handleDropClick = (e: any) => {
    e.stopPropagation();
    setIsOpenSubmenu(!isOpenSubmenu);
  };

  const handleSelect = () => {
    if (key !== 'columns' && !isStatic) {
      onSelect?.(index, key, title, checkIsSelected, parentItem, subMenuItems);
    }
  };

  const checkIsSelected = useMemo(() => {
    if (!isStatic) {
      if (index === metadata.index) {
        if (key === CONNECTION_KEY) {
          return metadata[key] === obj['id'];
        } else if (key === TABLE_KEY) {
          return metadata[key] ? metadata[key].includes(title) : false;
        } else {
          return metadata[key] === title;
        }
      } else {
        return false;
      }
    } else {
      if (!metadata[CONNECTION_KEY] && obj.isActive) {
        return true;
      } else {
        return false;
      }
    }
  }, [metadata, key, obj, title, index, isStatic]);

  const handleRefresh = (e: any) => {
    e.stopPropagation();
    trackRefreshDbConnection();
    onRefresh?.(obj.id);
  };

  const handleDelete = (e: any) => {
    e.stopPropagation();
    trackDeleteDbConnection();
    onDelete?.(obj.id, index);
  };

  const onDoubleClick = () => {
    if (key !== 'columns' && !isStatic) {
      onSelect?.(index, key, title, false, parentItem, subMenuItems);
    }
    dispatch(setQuickInsertText(title));
  };

  const [handleClick, handleDoubleClick] = useClickPreventionOnDoubleClick(
    handleSelect,
    onDoubleClick,
  );

  return (
    <div
      key={`navigation-item-${index}-${key}-${title}`}
      className={`${styles.navigation_item} ${
        obj.hide ? styles.hide_item : ''
      }`}
    >
      <Tooltip title={title} enterDelay={1000}>
        <button
          onClick={() => {
            handleClick?.();
            // TODO: router push action
          }}
          onDoubleClick={(e) => {
            handleDoubleClick();
          }}
          className={`${styles.navigation_item_body}  ${
            checkIsSelected ? styles.selected_item : ''
          } ${
            (!isCollapsed || isPinned) &&
            checkIsSelected &&
            key === CONNECTION_KEY
              ? styles.active_menu
              : styles.normal_menu
          }`}
        >
          <div
            className={`${
              isCollapsed && !isPinned
                ? 'flex_justify_items_center'
                : 'flex_justify_items_start'
            } ${styles.overflow_text}`}
          >
            {Icon && (
              <Icon
                className={`${
                  isOpenSubmenu
                    ? styles.navigation_item_icon
                    : styles.navigation_sub_item_icon
                } ${isCollapsed && !isPinned && styles.no_spacing}`}
              />
            )}

            {(!isCollapsed || isPinned) && (
              <span
                className={
                  isOpenSubmenu
                    ? styles.navigation_item_title
                    : styles.navigation_sub_item_title
                }
              >
                {title}
              </span>
            )}
          </div>

          {(!isCollapsed || isPinned) &&
            key === CONNECTION_KEY &&
            !isStatic &&
            !loading && (
              <div className={styles.action_btn_group}>
                <MdOutlineRefresh onClick={(e) => handleRefresh(e)} />
                <MdDeleteOutline onClick={(e) => handleDelete(e)} />
              </div>
            )}

          {/* TODO: */}
          {(!isCollapsed || isPinned) && subMenuItems?.length > 0 && (
            <div
              className={styles.navigation_subitems_arrow}
              onClick={handleDropClick}
            >
              {isOpenSubmenu ? leftArrowButton : downArrowButton}
            </div>
          )}
        </button>
      </Tooltip>
      {isOpenSubmenu && (
        <div
          className={`${styles.navigation_subitems} ${
            isCollapsed && !isPinned && 'no_spacing'
          }`}
        >
          {subMenuItems?.map((item: any) => (
            <Fragment key={index}>
              <NavigationItem
                obj={item}
                isStatic={isStatic}
                isCollapsed={isCollapsed}
                isPinned={isPinned}
                fieldIndex={fieldIndex + 1}
                onSelect={onSelect}
                index={index}
                parentItem={title}
              />
            </Fragment>
          ))}
        </div>
      )}
    </div>
  );
};
