import styles from './current-user.module.css';
import { useMemo, useRef, useState } from 'react';
import { useAuth } from 'oidc-react';
import ThemeSwitchButton from 'components/theme-switch-button';
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@mui/material';
import {
  setShowPrivacyModal,
  setShowSettingsModal,
} from 'store/slices/core/slice';

//App insights tracking
import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js';
import { fetchIntials } from 'utils/common';
import { IoIosSettings, IoMdDocument, IoIosLogOut } from 'react-icons/io';
import { useDispatch, useSelector } from 'react-redux';
import { selectCoreSlice } from 'store/slices/core/selectors';
import { setIsLightMode } from 'store/slices/core/slice';
import useResponsive from 'utils/hooks/useResponsive';

const CurrentUser = ({ isCollapsed = false, isPinned = false }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const auth: any = useAuth();
  const isLightMode = useSelector(selectCoreSlice).isLightMode;
  const ref = useRef<HTMLDivElement>(null);
  const { isMobile } = useResponsive();

  const handleClick = (event: any) => {
    setAnchorEl(ref.current);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onClickSignOut = () => {
    trackSignOut();
    handleClose();
    auth.signOutRedirect();
  };

  const handlePrivacyClick = () => {
    trackOpenPrivacyPolicyModal();
    handleClose();
    dispatch(setShowPrivacyModal(true));
  };

  // Tracking
  const appInsights = useAppInsightsContext();
  const trackOpenPrivacyPolicyModal = useTrackMetric(
    appInsights,
    'PRIVACY-POLICY-MODAL-open',
  );
  const trackSignOut = useTrackMetric(appInsights, 'USER-sign-out');

  const getUsername = useMemo(() => {
    try {
      let str = '';
      if (
        auth.userData?.profile?.first_name &&
        auth.userData?.profile?.first_name !== 'No Name'
      ) {
        str += auth.userData?.profile?.first_name + ' ';
      }
      if (
        auth.userData?.profile?.last_name &&
        auth.userData?.profile?.last_name !== 'No Name'
      ) {
        str += auth.userData?.profile?.last_name;
      }
      if (str.trim().length === 0) {
        return auth.userData?.profile?.sub.split('@')[0];
      }

      return str;
    } catch (error) {
      return '';
    }
  }, [
    auth.userData?.profile?.first_name,
    auth.userData?.profile?.last_name,
    auth.userData?.profile?.sub,
  ]);

  const getIntials = useMemo(() => fetchIntials(auth), [auth]);

  const onClickSettings = () => {
    dispatch(setShowSettingsModal(true));
    handleClose();
  };

  const onClickTheme = () => {
    let value = !isLightMode;
    dispatch(setIsLightMode(value));
    localStorage.setItem('isLightMode', value.toString());
  };

  return (
    <>
      <div
        className={styles.root}
        onClick={handleClick}
        data-testid="current-user"
      >
        <div className={styles.user} ref={ref}>
          <div
            className={
              !isCollapsed || isPinned
                ? anchorEl
                  ? styles.user_names_active
                  : styles.user_names
                : styles.pinned_wrap
            }
          >
            <div className={styles.badge}>{getIntials.toUpperCase()}</div>
            {(!isCollapsed || isPinned) && (
              <div className={styles.name_wrap}>{getUsername}</div>
            )}
          </div>
          {/* {(!isCollapsed || isPinned) && (
            <BiChevronDown className={styles.chevron} />
          )} */}
        </div>
      </div>
      <Menu
        anchorOrigin={{
          vertical: isMobile ? -233 : -175,
          horizontal: 0,
        }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        disableAutoFocusItem={true}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        PaperProps={{
          style: {
            backgroundColor: '#202123',
            overflowY: 'hidden',
            width: 230,
            border: '1px solid rgb(64, 65, 79',
          },
        }}
      >
        <MenuItem onClick={onClickTheme}>
          <ListItemIcon>
            <ThemeSwitchButton />
          </ListItemIcon>
          <ListItemText className={styles.model_title}>
            {isLightMode ? 'Light' : 'Dark'}
          </ListItemText>
        </MenuItem>
        <MenuItem onClick={handlePrivacyClick}>
          <ListItemIcon>
            <IoMdDocument className={styles.logout_icon} />
          </ListItemIcon>
          <ListItemText className={styles.model_title}>Policies</ListItemText>
        </MenuItem>
        <MenuItem onClick={onClickSettings}>
          <ListItemIcon>
            <IoIosSettings className={styles.logout_icon} />
          </ListItemIcon>
          <ListItemText className={styles.model_title}>Setting</ListItemText>
        </MenuItem>
        <Divider className={styles.divider} />
        <MenuItem onClick={onClickSignOut}>
          <ListItemIcon>
            <IoIosLogOut className={styles.logout_icon} />
          </ListItemIcon>
          <ListItemText className={styles.model_title}>Logout</ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};

export default CurrentUser;
