import createSagaMiddleware from '@redux-saga/core';
import { configureStore } from '@reduxjs/toolkit';
import { ICoreState } from '../models/store/store-models';
import rootSaga from './rootSaga';
import coreReducer, {
  initialState as initialCoreState,
} from './slices/core/slice';
import sessionReducer from './slices/session/slice';
import mainReducer from './slices/main/slice';

const getStore = (coreState: ICoreState = initialCoreState) => {
  let sagaMiddleware = createSagaMiddleware();
  const middleware = [sagaMiddleware];

  const store = configureStore({
    reducer: {
      core: coreReducer,
      session: sessionReducer,
      main: mainReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(middleware),
  });

  sagaMiddleware.run(rootSaga);
  return store;
};

export default getStore;
