import styles from './checkbox.module.css';

export interface IProps {
  label: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
}

const CheckboxButton = ({ label, checked, setChecked }: IProps) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(!checked);
  }
  return (
    <label className={styles.label}>
      <input
        className={styles.input}
        type="checkbox"
        checked={checked}
        onChange={onChange}
      />
      <span className={styles.checkmark}></span>
      {label}
    </label>
  );
}

export default CheckboxButton;