import { useRef, useEffect, useCallback } from 'react';
import styles from './privacy-policy-modal.module.css';
import { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { policies, termsAndConditions } from 'constant';
import { useDispatch, useSelector } from 'react-redux';
import { setShowPrivacyModal } from 'store/slices/core/slice';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import useClickOutside from 'utils/hooks/useClickOutside';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const PrivacyPolicyModal = () => {
  const [value, setValue] = useState(0);
  // tracking use
  // const [strinValue, setStringValue] = useState<string>("");

  const [isShowModal, setIsShowModal] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const listInnerRef = useRef<HTMLDivElement>(null);

  //redux state
  const { showPrivacyModal } = useSelector((state: any) => state.core);
  const dispatch = useDispatch();

  // onClick outside ref
  const ref = useRef<HTMLDivElement>(null);

  const onClickOutside = useCallback(() => {
    dispatch(setShowPrivacyModal(false));
    setIsShowModal(false);
  }, [dispatch]);

  useClickOutside(ref, onClickOutside);

  const handleScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;

      if (scrollTop + clientHeight + 1 >= scrollHeight) {
        if (!enabled) {
          setEnabled(true);
        }
      }
    }
  };

  useEffect(() => {
    if (listInnerRef?.current && !enabled) {
      const { scrollHeight, clientHeight } = listInnerRef?.current;
      if (clientHeight === scrollHeight) {
        setEnabled(true);
      }
    }
  }, [enabled]);

  useEffect(() => {
    if (showPrivacyModal) {
      setIsShowModal(true);
    }
  }, [showPrivacyModal]);

  if (!isShowModal) {
    return <></>;
  }

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    const value = Number(newValue);
    setValue(value);
  };

  const options = ['Terms of use', 'Policies'];

  return (
    <div className={styles.popup}>
      <div className={styles.popup_inner} ref={ref}>
        <div className={styles.content_wrap}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              className={styles.tabs_wrap}
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              {options.map((item) => (
                <Tab label={item} />
              ))}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div
              className={styles.main_content}
              ref={listInnerRef}
              onScroll={handleScroll}
            >
              <ReactMarkdown children={termsAndConditions || ''} />
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div
              className={styles.main_content}
              ref={listInnerRef}
              onScroll={handleScroll}
            >
              <ReactMarkdown children={policies || ''} />
            </div>
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyModal;
