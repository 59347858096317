import axios, { AxiosInstance } from 'axios';
import { IHeader } from 'models/api/helpers';
import { getAccessToken } from 'utils/common';

export interface IHttpClient {
  get<TResponse>(path: string, customHeaders: IHeader): Promise<TResponse>;
  post<TRequest, TResponse>(
    path: string,
    object: TRequest,
    customHeaders: IHeader,
  ): Promise<TResponse>;
  put<TRequest, TResponse>(
    path: string,
    object: TRequest,
    customHeaders: IHeader,
  ): Promise<TResponse>;
}

export default class HttpClient implements IHttpClient {
  private client: AxiosInstance;
  protected createClient(baseUrl: string): AxiosInstance {
    return axios.create({
      withCredentials: true,
      baseURL: baseUrl,      
      headers: {
        'Content-type': 'application/json',
      },
    });
  }

  constructor(baseUrl: string) {
    this.client = this.createClient(baseUrl);
  }

  async post<TRequest, TResponse>(
    path: string,
    payload: TRequest,
    headers: IHeader = {},
  ): Promise<TResponse> {
    try {
      const header = {
          Authorization: getAccessToken(),
          ...headers
      };
      const response = await this.client.post<TResponse>(path, payload, {
        headers: header,
      });
      return response.data;
    } catch (error) {
      // handleServiceError(error);
    }
    return {} as TResponse;
  }

  async put<TRequest, TResponse>(
    path: string,
    payload: TRequest,
    headers: IHeader = {},
  ): Promise<TResponse> {
    try {
      const header = {
        Authorization: getAccessToken(),
        ...headers
      };
      const response = await this.client.put<TResponse>(path, payload, {
        headers: header,
      });
      return response.data;
    } catch (error) {
      // handleServiceError(error);
    }
    return {} as TResponse;
  }

  async get<TResponse>(
    path: string,
    headers: IHeader = {},
  ): Promise<TResponse> {
    try {
      const header = {
        Authorization: getAccessToken(),
        ...headers
      };
      const response = await this.client.get<TResponse>(path, {
        headers: header,
      });
      return response.data;
    } catch (error) {
      // handleServiceError(error);
    }
    return {} as TResponse;
  }
}
