import React from 'react'
import styles from './switch-field.module.css'
import Switch from '@mui/material/Switch';

interface InputProps {
    label?: string;
    value?: any;
    onChange?: (value: any) => void;
}

const SwitchField = ({ label, value, onChange }: InputProps) => {

    return (
        <div className={styles.switch_wrap}>
            <div className={styles.label}>{label}</div>
            <Switch onChange={(e)=>onChange?.(e.target.checked)} checked={value === true ? true : false} />
        </div>
    )

}

export default SwitchField