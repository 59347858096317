import styles from './theme-switch-button.module.css';
import { useSelector } from 'react-redux';
import { selectCoreSlice } from 'store/slices/core/selectors';
import { ReactComponent as SunSVG } from './sun.svg';
import { ReactComponent as MoonSVG } from './moon.svg';

const ThemeSwitchButton = () => {
  const isLightMode = useSelector(selectCoreSlice).isLightMode;

  return (
    <div
      className={`${styles.theme_switch_wrap} ${
        isLightMode ? styles.moon_wrap : styles.sun_wrap
      }`}
    >
      {isLightMode ? <SunSVG /> : <MoonSVG />}
    </div>
  );
};

export default ThemeSwitchButton;
