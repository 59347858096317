import HttpClient from './base-client';
// import UserService from 'api/users.service';
import SettingsService from 'api/settings.service';

let settingsService: SettingsService;
// let userService: UserService;

export const configureClients = () => {
  settingsService = new SettingsService(
    new HttpClient(process.env.REACT_APP_SETTINGS_BASE_URL || ''),
  );
  // userService = new UserService(client);
};

export const GetSettingsService = () => settingsService;
// export const GetUserService = () => userService;

// export const getConfig = async () => {
//   try {
//     const res = await axios.get(
//       `${process.env.REACT_APP_SETTINGS_BASE_URL}/settings`,
//     );
//     console.log(res);
//     return res.data;
//   } catch (e) {
//     console.log(e);
//     return null;
//   }
// };
