import { useState } from "react"
import { BsCheck2 } from "react-icons/bs"
import { MdContentCopy } from "react-icons/md";
import styles from "./coppy-button.module.css"

//App insights tracking
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';


const CopyButton = ({ text, type = "SQL-EXAMPLE-QUERY", preText, postText }: { text: string, type: 'SQL-EXAMPLE-QUERY' | 'RESPONSE-MESSAGE', preText?: string, postText?:string }) => {
    const [isCopy, setIsCopy] = useState(false)

    // Tracking 
    const appInsights = useAppInsightsContext();
    const trackUseCopyButton = useTrackMetric(appInsights, `CHATBOX-COPY-BUTTON-${type}`)

    const handleCopy = (e: any) => {
        e.stopPropagation()
        trackUseCopyButton()
        var textField = document.createElement('textarea')
        textField.innerText = text
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
        setIsCopy(true)
        setTimeout(() => {
            setIsCopy(false)
        }, 3000)
    }

    return isCopy ? (
      <BsCheck2 className={styles.svg} />
    ) : (
      <div className={styles.root} onClick={handleCopy}>
        {!!preText && <p className={styles.preText}>{preText}</p>}
        <MdContentCopy className={styles.svg} onClick={handleCopy} />
        {!!postText && <p className={styles.postText}>{postText}</p>}

      </div>
    );
}

export default CopyButton