import axios from "axios";
import { AUTHORITY_URL, BASE_URL, CONNECTION_BASE_URL, FEEDBACK_BASE_URL, } from "config";
import { getAccessToken } from "utils/common";

export const getChat = async (body: any, callback: any, error: any) => {

    await axios.post(`${BASE_URL}/Conversation`, body, {
        headers: {
            Authorization: getAccessToken()
        }
    })
        .then((res) => callback(res?.data || {}))
        .catch((err) => error(err))
}

export const sendFeedback = async (body: any, callback: any, error: any) => {
    await axios.post(`${FEEDBACK_BASE_URL}/api/feedback`, body, {
        headers: {
            Authorization: getAccessToken()
        }
    })
        .then((res) => callback(res?.data || {}))
        .catch((err) => error(err))
}

export const getConnection = async (body: any, callback: any, error: any) => {
    await axios.post(`${CONNECTION_BASE_URL}/api/v1/Connections`, body, {
        headers: {
            Authorization: getAccessToken()
        }
    })
        .then((res) => callback(res?.data || {}))
        .catch((err) => error(err))
}

export const getAllConnections = async (callback: any, error: any) => {
    await axios.get(`${CONNECTION_BASE_URL}/api/v1/Connections`, {
        headers: {
            Authorization: getAccessToken()
        }
    })
        .then((res) => callback(res?.data || {}))
        .catch((err) => error(err))
}

export const deleteConnection = async (id: string, callback: any, error: any) => {
    await axios.delete(`${CONNECTION_BASE_URL}/api/v1/Connections/${id}`, {
        headers: {
            Authorization: getAccessToken()
        }
    })
        .then((res) => callback(res?.data || {}))
        .catch((err) => error(err))
}

export const acceptTC = async (body: any, callback: any, error: any) => {
    await axios.post(`${AUTHORITY_URL}/api/TermsAndCondition`, body, {
        headers: {
            Authorization: getAccessToken()
        }
    })
        .then((res) => callback(res?.data || {}))
        .catch((err) => error(err))
}

export const updateProfile = async (body: any, callback: any, error: any) => {
    await axios.post(`${FEEDBACK_BASE_URL}/api/updateProfile`, body, {
        headers: {
            Authorization: getAccessToken()
        }
    })
        .then((res) => callback(res?.data || {}))
        .catch((err) => error(err))
}