import React from 'react';

const useResponsive = () => {
  const [isMobile, setIsMobile] = React.useState<boolean>(window.innerWidth <= 768);

  React.useEffect(() => {
    const handleResize = () => {
      // Check the window width to determine if it's a mobile device
      setIsMobile(window.innerWidth <= 768); // You can adjust the threshold according to your design
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return { isMobile };
};

export default useResponsive;
