import styles from './placeholder-loader.module.css';

interface PlaceholderProps {
  isDarkBackground?: boolean;
}
// to use, wrap component in a div with fixed width and height.
const Placeholder = ({ isDarkBackground = false }: PlaceholderProps) => {
  return isDarkBackground ? (
    <div className={styles.placeholder} data-testid="placeholder"></div>
  ) : (
    <div className={styles.dark_placeholder} data-testid="placeholder"></div>
  );
};

export default Placeholder;
