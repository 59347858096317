import { IActionPayload, ICoreState } from 'models/store/store-models';
import { createSlice } from '@reduxjs/toolkit';
import { Profile } from 'models/profile';
import { Settings } from 'models/settings';

export const initialState: ICoreState = {
  user: sessionStorage.getItem('userData')
    ? JSON.parse(sessionStorage.getItem('userData') || '')
    : undefined,
  connectionData: [],
  showTCModal: false,
  showPrivacyModal: false,
  sidebarPinned: true,
  showConnectionModal: false,
  metadata: {},
  toastData: { open: false },
  quickInsertText: '',
  showConnectionBtn: false,
  showSettingsModal: false,
  showOnBoardModal: false,
  isLightMode: localStorage.getItem('isLightMode') === 'true' ? true : false,
  settings: { 'openai-key': undefined },
};

const coreSlice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    setUser(state: ICoreState, action: IActionPayload<Profile>) {
      state.user = action.payload;
    },
    setShowTCModal(state: ICoreState, action: IActionPayload<boolean>) {
      state.showTCModal = action.payload;
    },
    setShowPrivacyModal(state: ICoreState, action: IActionPayload<boolean>) {
      state.showPrivacyModal = action.payload;
    },
    setShowOnBoardModal(state: ICoreState, action: IActionPayload<boolean>) {
      state.showOnBoardModal = action.payload;
    },
    setSidebarPinned(state: ICoreState, action: IActionPayload<boolean>) {
      state.sidebarPinned = action.payload;
    },
    setShowConnectionModal(state: ICoreState, action: IActionPayload<boolean>) {
      state.showConnectionModal = action.payload;
    },
    setConnectionData(state: ICoreState, action: IActionPayload<any>) {
      state.connectionData = action.payload;
    },
    setMetadata(state: ICoreState, action: IActionPayload<any>) {
      state.metadata = action.payload;
    },
    setToastData(state: ICoreState, action: IActionPayload<any>) {
      state.toastData = action.payload;
    },
    setQuickInsertText(state: ICoreState, action: IActionPayload<string>) {
      state.quickInsertText = action.payload;
    },
    setShowConnectionBtn(state: ICoreState, action: IActionPayload<boolean>) {
      state.showConnectionBtn = action.payload;
    },
    setIsLightMode(state: ICoreState, action: IActionPayload<boolean>) {
      state.isLightMode = action.payload;
    },
    setShowSettingsModal(state: ICoreState, action: IActionPayload<boolean>) {
      state.showSettingsModal = action.payload;
    },
    setSettings(state: ICoreState, action: IActionPayload<Settings>) {
      state.settings = action.payload;
    },
  },
});

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const {
  setUser,
  setShowTCModal,
  setShowPrivacyModal,
  setSidebarPinned,
  setShowConnectionModal,
  setConnectionData,
  setMetadata,
  setToastData,
  setQuickInsertText,
  setShowConnectionBtn,
  setIsLightMode,
  setSettings,
  setShowSettingsModal,
  setShowOnBoardModal,
} = coreSlice.actions;

// Export the slice reducer as the default export
export default coreSlice.reducer;
