import { useRef, useCallback, useEffect } from 'react';
import styles from './settings-modal.module.css';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setShowSettingsModal } from 'store/slices/core/slice';
import useClickOutside from 'utils/hooks/useClickOutside';
import { AiOutlineClose } from 'react-icons/ai';
import SettingContent from './setting-content';
import QuestionSlider from 'containers/question-slider';
import useSignalrConnection from 'utils/hooks/useSignalrConnection';
import { selectPreferencesProfile } from 'store/slices/main/selectors';
import { setPreferencesProfile } from 'store/slices/main/slice';

enum SettingsSectionTypes {
  PREFERENCES = 'Preferences',
  OPENAI = 'Open AI',
}

const SettingsModal = () => {
  const dispatch = useDispatch();
  const profile = useSelector(selectPreferencesProfile);
  const [isProfileLoading, setProfileLoading] = useState(
    profile ? false : true,
  );
  const { connection } = useSignalrConnection();

  const [activeSection, setActiveSection] = useState(
    SettingsSectionTypes.PREFERENCES,
  );

  const handleClose = useCallback(() => {
    dispatch(setShowSettingsModal(false));
  }, [dispatch]);

  const onClickOutside = useCallback(() => {
    handleClose();
  }, [handleClose]);

  const sections = [
    {
      id: SettingsSectionTypes.PREFERENCES,
      label: SettingsSectionTypes.PREFERENCES,
      content: (
        <QuestionSlider
          profile={profile}
          loading={isProfileLoading}
          setProfileLoading={setProfileLoading}
        />
      ),
    },
    {
      id: SettingsSectionTypes.OPENAI,
      label: SettingsSectionTypes.OPENAI,
      content: <SettingContent />,
    },
  ];

  const modalRef = useRef(null);
  useClickOutside(modalRef, onClickOutside, true);

  useEffect(() => {
    const getProfileOptions = async () => {
      try {
        let res = await connection?.invoke('getProfile');
        dispatch(setPreferencesProfile(res));
      } catch (error) {}
      setProfileLoading(false);
    };
    if (!profile) {
      getProfileOptions();
    }
  }, [connection, profile]);

  return (
    <div className={styles.popup}>
      <div className={styles.popup_inner} ref={modalRef}>
        <div className={styles.tabordion}>
          <AiOutlineClose className={styles.close_icon} onClick={handleClose} />
          {sections.map((section) => (
            <section key={section.id} id={section.id}>
              <input
                type="radio"
                name="sections"
                id={`option${section.id}`}
                checked={activeSection === section.id}
                className={
                  activeSection === section.id ? styles.activeSelected : ''
                }
                onChange={() => setActiveSection(section.id)}
              />
              <label
                className={
                  activeSection === section.id
                    ? styles.section_label_text_focused
                    : styles.section_label_text
                }
                htmlFor={`option${section.id}`}
              >
                {section.label}
              </label>
              <article style={{ height: '100%' }}>{section.content}</article>
            </section>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
