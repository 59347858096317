import { IActionPayload, ISessionState } from 'models/store/store-models';
import { createSlice } from '@reduxjs/toolkit';
import { ConversationHistoryData } from 'utils/hooks/useSignalrConnection';

export const initialState: ISessionState = {
  sessionId: '',
  startSessionHistory: undefined,
};

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSessionId(
      state: ISessionState,
      action: IActionPayload<ConversationHistoryData | undefined>,
    ) {
      state.sessionId = action.payload?.sessionId || '';
      state.startSessionHistory = action.payload;
    },
  },
});

// `createSlice` automatically generated action creators with these names.
// export them as named exports from this "slice" file
export const { setSessionId } = sessionSlice.actions;

// Export the slice reducer as the default export
export default sessionSlice.reducer;
