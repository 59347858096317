import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js';
import styles from './Footer.module.css';
import CurrentUser from 'components/navbar/current-user';
import Popup from 'components/popup';
import { useState, useEffect } from 'react';
import { AiFillStar } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { sendFeedback } from 'service';
import { setToastData } from 'store/slices/core/slice';
import feedbackStyles from 'components/feedback-button/feedback-button.module.css';

type FooterParams = {
  isCollapsed: boolean;
  isPinned: boolean;
  messageId: string;
  sessionID: string;
};

export const Footer = ({
  isCollapsed = false,
  isPinned = false,
  messageId,
  sessionID,
}: FooterParams) => {
  return (
    <div
      className={`${styles.root} ${
        (!isCollapsed || isPinned) && 'flex_align_items_start'
      }`}
    >
      <FeedbackButtonFooter
        sessionID={sessionID}
        messageId={messageId}
        isFullMessageCopy={!isCollapsed || isPinned}
        isShowModalOnLike={true}
      />
      <CurrentUser isPinned={isPinned} isCollapsed={isCollapsed} />
    </div>
  );
};

interface Props {
  messageId?: string;
  isResetOnMessageID?: boolean;
  sessionID?: string;
  isFullMessageCopy?: boolean;
  isShowModalOnLike?: boolean;
}

export const FeedbackButtonFooter = ({
  isFullMessageCopy = false,
  messageId,
  isResetOnMessageID,
  sessionID,
  isShowModalOnLike,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const appInsights = useAppInsightsContext();
  const trackOpenFeedBackModal = useTrackMetric(
    appInsights,
    'FEEDBACK-MODAL-open',
  );
  const trackSentFeedBack = useTrackMetric(
    appInsights,
    'FEEDBACK-MODAL-sent-opinion',
  );

  useEffect(() => {
    if (isResetOnMessageID) {
      setIsOpen(false);
      dispatch(setToastData({ open: false }));
    }
  }, [isResetOnMessageID, messageId, dispatch]);

  const handleOpen = (e: any, value: boolean) => {
    e.stopPropagation();
    trackOpenFeedBackModal();
    setIsOpen(true);
  };

  const handeClose = () => {
    setIsOpen(false);
  };

  const handleSubmit = (value: any) => {
    trackSentFeedBack();
    sendFeedback(
      { thumbsUp: false, ...value, sessionID, chatID: messageId },
      (res: any) => {
        dispatch(
          setToastData({
            open: true,
            message: 'Feedback saved!',
            type: 'success',
          }),
        );
      },
      (err: any) => {
        dispatch(
          setToastData({
            open: true,
            message: 'Feedback not saved!',
            type: 'error',
          }),
        );
      },
    );
    setIsOpen(false);
  };

  return (
    <>
      <div
        onClick={(e) => handleOpen(e, true)}
        className={feedbackStyles.feedBackButton}
      >
        <div className={feedbackStyles.star_icon}>
          <AiFillStar />
        </div>
        <p className={feedbackStyles.feedback_text}>Feedback</p>
      </div>
      {isOpen && (
        <Popup
          isDefaultIcon={false}
          liked={false}
          showExtraField={!false}
          handleClose={handeClose}
          handleSubmit={handleSubmit}
          headerText={'Provide additional feedback'}
          placeholder={
            'What was the issue with the response? How could it be improved?'
          }
        />
      )}
    </>
  );
};

export default Footer;
