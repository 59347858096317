import CheckboxButton from 'components/checkbox';
import { FC, useCallback, useRef, useState } from 'react';
import { AiOutlineClose, AiOutlineDislike, AiOutlineLike } from 'react-icons/ai'
import useClickOutside from 'utils/hooks/useClickOutside';
import styles from './popup.module.css'
import Rating from '@mui/material/Rating';

interface Props {
    isDefaultIcon?: boolean;
    headerText?: string;
    liked?: boolean;
    placeholder?: string;
    showExtraField?: boolean;
    showRatings?: boolean;
    isRequired?: boolean;
    handleClose: () => void;
    handleSubmit: (value: any) => void;
}

const Popup: FC<Props> = ({
    isDefaultIcon,
    headerText,
    liked,
    placeholder,
    showExtraField,
    showRatings,
    isRequired,
    handleClose,
    handleSubmit
}) => {
    const [data, setData] = useState<any>({})
    const [error, setError] = useState<any>(null)
    const ref = useRef<HTMLDivElement>(null);

    const onClickOutside = useCallback(() => {
        if (handleClose) {
            handleClose();
        }
    }, [handleClose]);

    useClickOutside(ref, onClickOutside);

    const handleChange = (name: string, value: any, isCheckData?: boolean) => {
        let newData = { ...data, [name]: value }
        setData(newData);

        if (isCheckData && error) {
            let str = newData['feedbackVerbatim'] || ''
            str = str.trim()
            if (str?.length > 0 || newData['rating'] > 0) {
                setError(false)
            }
        }

    }

    const onSubmit = () => {
        let str = data?.feedbackVerbatim || ''
        str = str.trim()
        if (((str?.length > 0 || data.rating > 0) && isRequired) || !isRequired) {
            handleSubmit(data)
        } else {
            setError(true)
        }
    }

    return (
        <div className={styles.popup}>
            <div className={styles.popup_inner} ref={ref}>

                {/* Header */}
                <div className={styles.header}>
                    <div>
                        {isDefaultIcon ?
                            <div></div>
                            :
                            <div className={styles.popup_icons}>
                                {liked ? <AiOutlineLike className={styles.like} /> : <AiOutlineDislike className={styles.dislike} />}
                            </div>
                        }
                        {headerText}
                    </div>
                    <div >
                        <AiOutlineClose className={styles.close_icon} onClick={handleClose} />
                    </div>
                </div>


                {/* Main content */}
                <div className={styles.child_content}>
                    <div>
                        <textarea className={styles.input_box} placeholder={placeholder} onChange={(e) => handleChange('feedbackVerbatim', e.target.value, true)} />
                    </div>

                    {showExtraField &&
                        <div className={styles.extra_fields}>
                            <CheckboxButton
                                label="This is harmful / unsafe"
                                checked={data['harmful'] || false}
                                setChecked={(v) => handleChange('harmful', v)}
                            />
                            <CheckboxButton
                                label="This isn't true"
                                checked={data['notTrue'] || false}
                                setChecked={(v) => handleChange('notTrue', v)}
                            />
                            <CheckboxButton
                                label="This isn't helpful"
                                checked={data['notHelpful'] || false}
                                setChecked={(v) => handleChange('notHelpful', v)}
                            />
                        </div>
                    }

                    {showRatings &&
                        <div className={styles.rating_container}>
                            Rate us:

                            <Rating
                                name="simple-controlled"
                                className={styles.ratings}
                                value={data['rating'] || 0}
                                onChange={(event, newValue) => {
                                    handleChange('rating', newValue, true);
                                }}
                            />
                        </div>
                    }

                    {error &&
                        <div className={styles.error_text}>Please write any reviews or give ratings</div>
                    }

                </div>

                {/* Bottom buttons */}
                <div className={styles.button_content}>
                    <button className={styles.submit_btn} onClick={onSubmit}>Submit feedback</button>
                </div>
            </div>
        </div>
    )

}

export default Popup