import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styles from './middle-section.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { selectCoreSlice } from 'store/slices/core/selectors';
import CopyButton from 'components/copy-button';
import { BsAt, BsDatabase } from 'react-icons/bs';
import { IoMdArrowRoundDown } from 'react-icons/io';

import FeedbackButton from 'components/feedback-button';
import { getChat } from 'service';
import { v4 as uuid } from 'uuid';
import SplashScreen from './splash-screen';
import ReactMarkdown from 'react-markdown';
import { InputAdornment, TextField } from '@mui/material';
import { BsArrowUpShort } from 'react-icons/bs';
import {
  CONNECTION_KEY,
  DATABASE_KEY,
  DATABASE_TYPE_KEY,
  TABLE_KEY,
  VERSION_KEY,
} from 'constant';
import moment from 'moment';
import logo from 'images/logo_main.svg';

//App insights tracking
import {
  useAppInsightsContext,
  useTrackMetric,
} from '@microsoft/applicationinsights-react-js';
import { downloadChat, fetchIntials } from 'utils/common';
import { setQuickInsertText } from 'store/slices/core/slice';
import { useAuth } from 'oidc-react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import vs2015 from 'react-syntax-highlighter/dist/esm/styles/hljs/vs2015';
import { useInView } from 'react-intersection-observer';
import ToolTip from 'components/tooltip';
import useResponsive from 'utils/hooks/useResponsive';
import { FiDownload } from 'react-icons/fi';
import { NavSvg } from 'images/Svgs';
import DatabaseContext from 'components/database-context';
import useSignalrConnection from 'utils/hooks/useSignalrConnection';
import { FaCheck, FaSpinner } from 'react-icons/fa';
import MUIDataTable from 'mui-datatables';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ChatTableComponent from 'containers/chat-table';
import Header from 'containers/header';
import BotName from 'containers/bot-name';
import ChatBottomSection from 'containers/chat-bottom';
import {
  selectSessionId,
  selectSessionStartHistory,
} from 'store/slices/session/selectors';

interface Props {
  toggleCollapse: (value: any) => void;
}

const MiddleSection: FC<Props> = ({ toggleCollapse }) => {
  const startMessages = useSelector(selectSessionStartHistory);
  const sessionId = useSelector(selectSessionId);
  const textareaRef = useRef<any>(null);
  // const [historyMessage, setHistoryMessage] = useState<any[]>([]);
  // const [isStart, setIsStart] = useState<boolean>(true);
  const [message, setMessage] = useState<string>('');
  const [messageList, setMessageList] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const { ref: scrollDownRef, inView } = useInView({
    threshold: 0,
  });
  const divRef = useRef<HTMLDivElement | null>(null);
  const bottomSectionRef = useRef<HTMLDivElement | null>(null);
  const [messages, setMessages] = useState<any>([]);
  // const [currentLine, setCurrentLine] = useState(1)
  // const [popup, setPopup] = useState(false)
  const metadata = useSelector(selectCoreSlice).metadata;
  const quickInsertText = useSelector(selectCoreSlice).quickInsertText;
  const dispatch = useDispatch();
  const auth: any = useAuth();
  // const [thumbsUp, setThumbsUp] = useState<any>()
  const { isMobile } = useResponsive();
  const { sendMessage, activityMessages } = useSignalrConnection();
  const [databaseModal, setDatabaseModal] = useState<boolean>(false);

  // Tracking
  // tracking structure copy: SECTION-events
  // example:
  const appInsights = useAppInsightsContext();
  const trackKeyboardInput = useTrackMetric(
    appInsights,
    'CHATBOX-keyboard-input',
  );
  const trackSendMessage = useTrackMetric(appInsights, 'CHATBOX-send-message');
  const trackSendMessageButton = useTrackMetric(
    appInsights,
    'CHATBOX-send-message-button',
  );

  useEffect(() => {
    setLoading(true);
    if (startMessages?.messages) {
      const startMessagesList = Object.entries(startMessages.messages).map(
        ([key, value]: any) => {
          return {
            bot: value?.role === 'system',
            user: value?.role === 'user',
            message: value?.content,
            activities: [],
            time: moment().format('hh:mm a'),
          };
        },
      );
      const newMessagesList = [
        ...startMessagesList,
        {
          bot: true,
          user: false,
          message: startMessages.message,
          activities: [],
          time: moment().format('hh:mm a'),
        },
      ];
      setMessageList(newMessagesList);
      setLoading(false);
    }
  }, [startMessages]);

  useEffect(() => {
    scrollIntoView();
  }, [loading, activityMessages]);

  useEffect(() => {
    if (quickInsertText) {
      setMessage(message + ' ' + quickInsertText);
      dispatch(setQuickInsertText(''));
    }
  }, [quickInsertText, dispatch, message]);

  const scrollIntoView = useCallback(() => {
    setTimeout(() => {
      // divRef.current?.scrollIntoView({ behavior: 'smooth' });
      const chatContainer = document.querySelector(`.${styles.chat_container}`);
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer?.scrollHeight;
      }
    }, 100);
  }, []);

  const handleSendMessage = async (value: any = message) => {
    trackSendMessage();

    if (!loading && value.length > 0) {
      //   if (newChat) {
      //     setNewChat(false);
      //   }
      setMessageList((prevState) => [
        ...prevState,
        { user: true, message: value, time: moment().format('hh:mm a') },
      ]);
      setMessage('');
      // setCurrentLine(1)
      setLoading(true);
      scrollIntoView();
      let body: any =
        messages.length > 0
          ? { message: value, messages: messages.slice(-10) }
          : { message: value };
      if (metadata) {
        let obj = {};

        if (metadata[CONNECTION_KEY]) {
          body = { ...body, connectionId: metadata[CONNECTION_KEY] };
          obj = {
            'Database Type': metadata[DATABASE_TYPE_KEY],
            Version: metadata[VERSION_KEY],
          };
        }

        if (metadata[DATABASE_KEY]) {
          obj = { ...obj, 'Database Name': metadata[DATABASE_KEY] };
        }
        if (metadata[TABLE_KEY]) {
          let tables = metadata[TABLE_KEY]?.reduce(
            (currentValue: string, nextValue: string, index: number) => {
              return nextValue
                ? currentValue +
                    nextValue +
                    ' | ' +
                    metadata.columns[nextValue].toString() +
                    (index === metadata[TABLE_KEY]?.length - 1 ? '' : '\n')
                : currentValue;
            },
            '',
          );
          obj = { ...obj, [TABLE_KEY]: tables };
        }
        body = { ...body, metadata: obj, sessionId };
      }

      sendMessage(
        body,
        (data: any, activities) => {
          if (data.error) {
            let messages = Object.values(data.messages);
            let index = messages?.length - 1;
            setMessages(messages);
            setMessageList((prevState) => [
              ...prevState,
              {
                bot: true,
                error: true,
                message: data?.messages?.[index]?.content,
                time: moment().format('hh:mm a'),
                activities,
              },
            ]);
          } else {
            let message = data.message;
            if (!data.message) {
              message = data.messages?.[0]?.content;
            }
            setMessageList((prevState) => [
              ...prevState,
              {
                ...data,
                bot: true,
                message: message,
                activities,
                id: data.id,
                time: moment().format('hh:mm a'),
              },
            ]);
          }
          setMessages(Object.values(data.messages));
          setLoading(false);
        },
        (err: any, activities) => {
          setMessageList((prevState) => [
            ...prevState,
            {
              bot: true,
              error: true,
              message:
                'Ooops something went wrong. Pleas share feedback and try again.',
              id: 'error',
              time: moment().format('hh:mm a'),
              activities,
            },
          ]);
          console.log(err);
          setLoading(false);
        },
      );
    }
  };

  const handleChange = (e: any) => {
    const value = e.target.value;
    setMessage(value);
    trackKeyboardInput();

    setTimeout(() => {
      if (textareaRef?.current) {
        const scrollHeight = textareaRef.current.clientHeight;
        let inputLines = Math.round(scrollHeight / 23);

        let lines = inputLines > 4 ? 4 : inputLines;
        // setCurrentLine?.(lines)
      }
      // if (e.target.value.length === 0) {
      //     setCurrentLine(1)
      // }
    }, 10);
  };

  const handleSave = () => {
    downloadChat(messageList);
  };

  const isMessages = useMemo(() => {
    return messageList.length > 0 || activityMessages.length > 0;
  }, [messageList.length, activityMessages.length]);

  const getIntials = useMemo(() => {
    return fetchIntials(auth);
  }, [auth]);

  const onSpacingDiv = useCallback(
    (ref: HTMLDivElement) => {
      scrollDownRef(ref);
      divRef.current = ref;
    },
    [scrollDownRef],
  );

  const paddingForHeader = useMemo(() => {
    return { paddingTop: isMobile ? 120 : 70 };
  }, [isMobile]);

  const ArrowDown = useCallback(() => {
    if (!isMessages) return null;

    return (
      <div style={{ visibility: inView ? 'hidden' : 'visible' }}>
        <button className={styles.scroll_down} onClick={scrollIntoView}>
          <IoMdArrowRoundDown size={25} />
        </button>
      </div>
    );
  }, [isMessages, inView]);

  const Loading = useCallback(() => {
    return (
      <>
        <div className={styles.bot_chat_box}>
          <BotName />
          <div className={styles.chat_content}>
            <div data-title="dot-typing">
              {activityMessages.map((activity: string, index: number) => {
                return (
                  <li>
                    <i>
                      <code className={styles.activityMessages}>
                        {activity}
                      </code>
                      {index === activityMessages.length - 1 ? (
                        <FaSpinner className={styles.spinning} />
                      ) : (
                        <FaCheck />
                      )}
                    </i>
                  </li>
                );
              })}
              {!activityMessages.length && (
                <div className={styles.loader}>
                  <div className={styles.dot_typing}></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }, [activityMessages.length]);

  const renderUserChat = ({ message }: any, index: number) => (
    <div className={styles.chat_box}>
      <div className={styles.chatset}>
        <div className={styles.icon_container}>
          <div className={styles.badge_icon}>{getIntials.toUpperCase()}</div>
        </div>
        <div className={styles.name_text}>You</div>
      </div>

      <div className={styles.user_chat_content}>
        <ReactMarkdown children={message || ''} />
      </div>
    </div>
  );

  const renderBotChat = (
    { message, error, id, activities, database }: any,
    index: number,
  ) => {
    return (
      <div className={`${styles.bot_chat_box}`}>
        <div className={styles.chatset}>
          <div className={styles.icon_container}>
            <div className={`${styles.badge}`}>
              <img className={styles.logo} src={logo} alt="logo" />
            </div>
          </div>
          <div className={styles.name_text}>Lumis</div>
        </div>

        <div className={error ? styles.error_message : styles.chat_content}>
          {activities.map((activity: string, index: number) => {
            let sqlStatement = '';
            if (activity.includes('Executing SQl:')) {
              const sqlIndex = activity.indexOf('`');
              if (sqlIndex !== -1) {
                sqlStatement = activity.substring(sqlIndex + 1);
                sqlStatement = sqlStatement.replace(/`/g, '');
              }
            }

            return (
              <li>
                <i>
                  {!sqlStatement ? (
                    <code className={styles.activityMessages}>{activity}</code>
                  ) : (
                    <>
                      <code className={styles.activityMessages}>
                        Executing SQl:
                      </code>
                    </>
                  )}
                  <FaCheck />
                  {sqlStatement && (
                    <>
                      <div className={styles.copy_code}>
                        <p className={styles.language}>sql</p>
                        <CopyButton
                          type="SQL-EXAMPLE-QUERY"
                          text={sqlStatement.toString()}
                          postText="Copy code"
                        />
                      </div>
                      <SyntaxHighlighter
                        lineProps={{
                          style: {
                            wordBreak: 'break-all',
                            whiteSpace: 'pre-line',
                          },
                        }}
                        children={String(sqlStatement).replace(/\n$/, '')}
                        style={vs2015}
                        // language="sql"
                        PreTag="div"
                        wrapLines={true}
                        customStyle={{
                          borderBottomLeftRadius: 5,
                          borderBottomRightRadius: 5,
                          padding: 10,
                          backgroundColor: '#000000',
                        }}
                      />
                    </>
                  )}
                </i>
              </li>
            );
          })}
          <br />
          <ReactMarkdown
            children={message || ''}
            components={{
              code({ children, className, ...rest }) {
                console.log('again call', className);

                const language = /language-(\w+)/.exec(className || '') || [];
                return (
                  <>
                    {language.length ? (
                      <>
                        <div className={styles.copy_code}>
                          <p className={styles.language}>{language[1]}</p>
                          <CopyButton
                            type="SQL-EXAMPLE-QUERY"
                            text={(children?.[0] + '').toString()}
                            postText="Copy code"
                          />
                        </div>
                        <SyntaxHighlighter
                          lineProps={{
                            style: {
                              wordBreak: 'break-all',
                              whiteSpace: 'pre-line',
                            },
                          }}
                          children={String(children).replace(/\n$/, '')}
                          style={vs2015}
                          language={language[1]}
                          PreTag="div"
                          wrapLines={true}
                          customStyle={{
                            borderBottomLeftRadius: 5,
                            borderBottomRightRadius: 5,
                            padding: 10,
                            backgroundColor: '#000000',
                          }}
                        />
                      </>
                    ) : (
                      <code {...rest} className={className}>
                        {children}
                      </code>
                    )}
                  </>
                );
              },
            }}
          />
        </div>
        {database?.tables?.map?.((table: any) => {
          return <ChatTableComponent table={table} />;
        })}
        {index === messageList.length - 1 && (
          <div className={styles.btn_group}>
            <CopyButton type="RESPONSE-MESSAGE" text={message} />
            <FeedbackButton
              messageId={id}
              sessionID={sessionId}
              isShowModalOnLike={false}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <Header
        isMobile={isMobile}
        isMessages={isMessages}
        handleSave={handleSave}
        toggleCollapse={toggleCollapse}
      />
      <div className={styles.chat_container}>
        {/* Splash message before any chat exist */}
        {!isMessages && !loading && (
          <SplashScreen
            setMessages={handleSendMessage}
            bottomRef={bottomSectionRef}
          />
        )}

        {/* Message section containing user and bot messages */}

        {isMessages && (
          <>
            <div style={paddingForHeader} />
            {messageList.map((obj, index) => (
              <React.Fragment key={index}>
                {obj.user && renderUserChat(obj, index)}
                {obj.bot && renderBotChat(obj, index)}
              </React.Fragment>
            ))}

            {/* Show typing loader while API calling */}
            {loading && <Loading />}

            <div className={styles.spacing_div} ref={onSpacingDiv}></div>
          </>
        )}
      </div>

      <ChatBottomSection
        message={message}
        textareaRef={textareaRef}
        handleChange={handleChange}
        handleSendMessage={handleSendMessage}
        setDatabaseModal={setDatabaseModal}
        trackSendMessageButton={trackSendMessageButton}
      />

      {/* Database Model */}
      <DatabaseContext open={databaseModal} onClose={setDatabaseModal} />
    </div>
  );
};

export default MiddleSection;
