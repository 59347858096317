import { Dispatch, useEffect } from 'react';
import { getAccessToken } from 'utils/common';
import { BASE_URL } from 'config';
import {
  HubConnectionBuilder,
  HubConnection,
  LogLevel,
} from '@microsoft/signalr';
import useStateRef from './useStateRef';
import { AnyAction } from '@reduxjs/toolkit';
import { setSessionId } from 'store/slices/session/slice';

export const ON_MESSAGE_ACTIVITY = 'onmessageactivity';
export const SIGNALR_START_SESSION = 'startSession';

export interface Message {
  role: string;
  content: string;
}

export interface Conversation {
  [index: string]: Message;
}

export interface ConversationHistoryData {
  sessionId: string;
  id: string;
  message: string;
  messages: Conversation;
  error: null | any;
  database: null | any;
  insights: null | any;
  command: null | any;
  relatedQuestion: null | any;
}

let signalr_connection: HubConnection | undefined;
const getSignalRConnection = () => {
  return signalr_connection;
};

export const getSession = async (
  connection: HubConnection,
  dispatch: Dispatch<AnyAction>,
) => {
  let conversationData: ConversationHistoryData = await connection.invoke(
    SIGNALR_START_SESSION,
  );
  dispatch(setSessionId(conversationData));
};

export const setupConnection = async (dispatch: Dispatch<AnyAction>) => {
  try {
    if (!signalr_connection) {
      signalr_connection = new HubConnectionBuilder()
        .withUrl(`${BASE_URL}/bothub`, {
          accessTokenFactory: () => {
            // Remove 'Bearer ' from the token as it's added automatically
            return getAccessToken().replace('Bearer ', '');
          },
        })
        .configureLogging(LogLevel.Information)
        .withAutomaticReconnect()
        .build();
      signalr_connection.serverTimeoutInMilliseconds = 120000;

      await signalr_connection.start();

      if (signalr_connection?.state === 'Connected') {
        getSession(signalr_connection, dispatch);
      }
      return {
        isConnected: signalr_connection?.state === 'Connected',
      };
    }
  } catch (e) {
    return {
      isConnected: false,
    };
    // TODO: Send alert
  }
};

export const isConnectionConnected = (
  connection: HubConnection | undefined,
) => {
  return connection?.state === 'Connected';
};

const useSignalrConnection = () => {
  const connection = getSignalRConnection();
  const isConnected = isConnectionConnected(connection);
  const [activityMessages, setActivityMessage, activityMessagesRef] =
    useStateRef<string[]>([]);

  useEffect(() => {
    if (connection) {
      connection.on(ON_MESSAGE_ACTIVITY, ({ content }) => {
        setActivityMessage((old) => [...old, content]);
      });
    }

    return () => {
      if (connection) {
        connection.off(ON_MESSAGE_ACTIVITY);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected]);

  // Define a function to send a message through the SignalR connection
  const sendMessage = async (
    message: string,
    cb: (...args: any[]) => any,
    errorCb: (...args: any[]) => any,
  ) => {
    if (connection) {
      // TODO: need to use correct session id
      try {
        cb(
          await connection.invoke('sendMessage', message),
          activityMessagesRef.current,
        );
      } catch (error) {
        errorCb(error, activityMessagesRef.current);
      }

      setActivityMessage([]);
    }
  };

  // Return relevant information and functions for external use
  return {
    connection,
    activityMessages,
    sendMessage,
    getSession,
  };
};

export default useSignalrConnection;
