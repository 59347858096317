import { Route, Routes, useNavigate } from 'react-router-dom';
import styles from './app.module.css';
import { AuthProvider, useAuth } from 'oidc-react';
import { useEffect } from 'react';
import Notification from 'components/notification';
import { AUTHORITY_URL, CLIENT_ID, REDIRECT_URL } from 'config';
import { useSelector } from 'react-redux';
import { selectCoreSlice } from 'store/slices/core/selectors';
import Home from 'containers/home/Home';

const oidcConfig = {
  onSignIn: async (user: any) => {
    // alert('You just signed in, congratz! Check out the console!');
    console.log(user);
    window.location.hash = '';
  },
  authority: AUTHORITY_URL,
  clientId: CLIENT_ID,
  responseType: 'code',
  redirectUri: REDIRECT_URL,
};

function App() {
  const isLightMode = useSelector(selectCoreSlice).isLightMode;

  return (
    <AuthProvider {...oidcConfig} automaticSilentRenew={true} autoSignIn={true}>
      <div
        className={`${styles.app} ${styles.root_colors} ${
          isLightMode ? styles.light : styles.dark
        }`}
      >
        <div className={styles.content}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/callback?" element={<Callback />} />
          </Routes>
        </div>
      </div>
      <Notification />
    </AuthProvider>
  );
}

export default App;

function Callback() {
  const auth = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    auth.userManager.signinRedirectCallback().then((user) => {
      navigate('/');
    });
  });
  return <></>;
}
