import React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MUIDataTable from 'mui-datatables';
import startCase from 'lodash/startCase';
import styles from './chat-table.module.css';

interface TableProps {
  table: {
    columns: any[];
    rows: any[];
  };
}

const ChatTableComponent: React.FC<TableProps> = ({ table }) => {
  const getMuiTheme = () =>
  createTheme({
    components: {
      MuiTable: {
        styleOverrides: {
          root: {
            overflow: 'hidden',
          },
        },
      },

      MUIDataTable: {
        styleOverrides: {
          root: {
            width: '100%',
            borderRadius: 10,
            overflow: 'hidden',
            marginBottom: 10,
            backgroundColor: '#0F1324',
            boxShadow: 'none',
            border: '2px solid #1E2631',
            borderBottom: 'none',
          },
        },
      },
      MuiTableRow: {
        styleOverrides: {
          root: {
            borderBottom: '2px solid #1E2631',
            color: 'white',
            overflow: 'hidden',
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          hintIconWithSortIcon: {
            color: 'white',
          },
          sortActive: {
            color: 'white',
          },
          root: {
            backgroundColor: '#0F1324',
            color: '#B7BABD',
            padding: 5,
            alignItems: 'center',
            textJustify: 'inter-word',
            paddingLeft: 20,
            borderLeft: 'none',
          },

          fixedHeader: {
            backgroundColor: 'transparent',
          },

          sortAction: {
            '& svg': {
              color: 'white !importnat',
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            // borderBottom: 'none',
            // borderRight: 'none',
            borderLeft: '2px solid #1E2631 ',
            // padding: '8px',
            '&:first-child': {
              borderLeft: 'none',
            },
          },
        },
      },

      MuiTableSortLabel: {
        styleOverrides: {
          icon: {
            color: 'white !important',
          },
          root: {
            color: 'white',
          },
          active: {
            color: 'white',
          },
        },
      },
      MUIDataTableBodyCell: {
        styleOverrides: {
          root: {
            color: '#B7BABD',
          },
        },
      },

      MUIDataTablePagination: {
        styleOverrides: {
          root: {
            color: '#B7BABD',
          },
        },
      },
      MUIDataTableFilterList: {
        styleOverrides: {
          root: {
            color: '#B7BABD',
          },
        },
      },
      MuiTableFooter: {
        styleOverrides: {
          root: {
            backgroundColor: '#0F1324',
            color: 'white',
          },
        },
      },
    },
  });

  return (
    <div className={styles.table_wrapper}>
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title=""
          data={table?.rows}
          columns={table?.columns.map((column: any) =>
            startCase(column.name),
          )}
          options={{
            search: false,
            download: false,
            print: false,
            viewColumns: false,
            filter: false,
            responsive: 'standard',
            rowsPerPageOptions: [10, 20],
            selectableRowsHideCheckboxes: true,
            rowHover: true,
            setRowProps: (row, dataIndex, rowIndex) => {
              return {
                style: {
                  // border: 'none', // Remove the border on the cell text
                  // borderRight: '2px solid red', // Change the border color between columns

                  background: '#161A20',
                  color: 'white',
                },
              };
            },
          }}
        />
      </ThemeProvider>
    </div>
  );
};

export default ChatTableComponent;
