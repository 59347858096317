import { useRef, useCallback, useEffect } from 'react';
import styles from './settings-content.module.css';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setSettings, setShowSettingsModal } from 'store/slices/core/slice';
import useClickOutside from 'utils/hooks/useClickOutside';
import InputField from 'components/input-field';
import SwitchField from 'components/switch-field';
import RadioField from 'components/radio-field';
import { GetSettingsService } from 'api/setup/setup';
import { selectSettings } from 'store/slices/core/selectors';
import { OpenAITypes, OpenAISetting } from 'models/settings';

const defaultOpenAISetting = {
  enabled: false,
  apiKey: '',
  azureEndpoint: '',
  type: OpenAITypes.OPENAI,
};

const SettingContent = () => {
  const storeSettings = useSelector(selectSettings);
  const initialStoreSettings = storeSettings['openai-key']
    ? storeSettings['openai-key']
    : defaultOpenAISetting;
  const modalRef = useRef(null);

  const [openAI, setOpenAI] = useState<OpenAISetting>(initialStoreSettings);
  const [showSave, setShowSave] = useState(false);
  const [error, setError] = useState('');
  const [azureEndpointError, setAzureEndpointError] = useState('');
  const dispatch = useDispatch();

  const { enabled, azureEndpoint, apiKey, type } = openAI;

  const handleClose = useCallback(() => {
    dispatch(setShowSettingsModal(false));
  }, [dispatch]);

  const APITypeOptions = [
    { label: 'OpenAI', value: OpenAITypes.OPENAI },
    { label: 'Azure OpenAI', value: OpenAITypes.AZURE_OPENAI },
  ];

  const onClickOutside = useCallback(() => {
    handleClose();
  }, [handleClose]);

  useClickOutside(modalRef, onClickOutside, true);

  const handleReset = () => {
    setOpenAI(initialStoreSettings);
    setShowSave(false);
  };

  const handleSubmit = () => {
    setError('');
    const settingsService = GetSettingsService();
    const req = { ...openAI };
    if (enabled) {
      if (type === OpenAITypes.OPENAI && !openAI.apiKey) {
        setError('Please enter a valid API Key');
        return;
      } else if (type === OpenAITypes.AZURE_OPENAI) {
        let hasError = false;
        if (!openAI.apiKey) {
          setError('Please enter a valid API Key');
          hasError = true;
        }
        const re = new RegExp('https://[a-zA-Z0-9-_]+.openai.azure.com');
        if (!re.test(openAI.azureEndpoint)) {
          setAzureEndpointError('Please enter valid Endpoint');
          hasError = true;
        }
        if (hasError) return;
      }
    }

    const response = settingsService.updateOpenAIKey(req);
    if (!response) {
      setError('Failed to save! Please try again');
    } else {
      const updatedSettings = {
        ...storeSettings,
        'openai-key': req,
      };
      dispatch(setSettings(updatedSettings));
      setError('');
      setAzureEndpointError('');
      setShowSave(false);
    }
  };

  const handleEnabledChange = () => {
    const updatedSettings = { ...openAI };
    updatedSettings.enabled = !enabled;
    setOpenAI(updatedSettings);
    if (!showSave) setShowSave(true);
  };

  const handleAPIKeyChange = (value: string) => {
    const updatedSettings = { ...openAI };
    updatedSettings.apiKey = value;
    setOpenAI(updatedSettings);
    if (!showSave) setShowSave(true);
  };
  const handleAPIEndpointChange = (value: string) => {
    const updatedSettings = { ...openAI };
    updatedSettings.azureEndpoint = value;
    setOpenAI(updatedSettings);
    if (!showSave) setShowSave(true);
  };

  const handleOpenAITypeChange = (value: OpenAITypes) => {
    const updatedSettings = { ...openAI };
    const newValue = value as OpenAITypes;
    updatedSettings.type = newValue;
    setOpenAI(updatedSettings);
    if (!showSave) setShowSave(true);
  };

  useEffect(() => {
    const settings = storeSettings['openai-key']
      ? storeSettings['openai-key']
      : defaultOpenAISetting;
    setOpenAI(settings);
  }, [storeSettings]);
  return (
    <div>
      <div className={styles.card}>
        <span className={styles.cardTitle}>Bring Your Own OpenAI Key</span>
        <div className={styles.field_container}>
          {/* <div className={styles.section_header}>Bring Your Own OpenAI Key</div> */}
          <SwitchField
            label="Enabled"
            value={enabled}
            onChange={handleEnabledChange}
          />
          {enabled && (
            <RadioField
              options={APITypeOptions}
              value={type}
              onChange={handleOpenAITypeChange}
            />
          )}
          {enabled && (
            <InputField
              label="API Key"
              placeholder="API Key"
              value={apiKey}
              isError={error.length > 0}
              helperText={error}
              onChange={handleAPIKeyChange}
            />
          )}
          {enabled && type === OpenAITypes.AZURE_OPENAI && (
            <InputField
              label="API Endpoint"
              placeholder="API Endpoint"
              value={azureEndpoint}
              isError={azureEndpointError.length > 0}
              helperText={azureEndpointError}
              onChange={handleAPIEndpointChange}
            />
          )}
          {showSave && (
            <div className={styles.button_content}>
              <button className={styles.cancel_btn} onClick={handleReset}>
                Cancel
              </button>
              <button className={styles.submit_btn} onClick={handleSubmit}>
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SettingContent;
